/**
 * 简繁体转换
 */
// utils
import { chinese } from './lang/zh';

const S = chinese.S; // 简体字

const T = chinese.T; // 繁体字

/**
 * 转换文本
 * @param {String} str - 待转换的文本
 * @param {Boolean} toT - 是否转换成繁体
 * @returns {String} - 转换结果
 */
function tranStr (str, toT) {
  let src, des;

  if (toT) {
    src = S;
    des = T;
  } else {
    src = T;
    des = S;
  }

  if (typeof str !== 'string') {
    return str;
  }

  let result = '';

  for (let i = 0; i < str.length; i++) {
    const letter = str.charAt(i);
    const code = str.charCodeAt(i);
    // 根据字符的 Unicode 判断是否为汉字，以提高性能
    const isChinese = (code > 0x3400 && code < 0x9FC3) || (code > 0xF900 && code < 0xFA6A);

    if (!isChinese) {
      result += letter;
      continue;
    }

    const index = src.indexOf(letter);

    if (index !== -1) {
      result += des.charAt(index);
    } else {
      result += letter;
    }
  }

  return result;
};

export const Chinese = {
  // s2t: function(str) { // 简体转繁体
  //   return tranStr(str, true)
  // },
  // t2s: function(str) { // 繁体转简体
  //   return tranStr(str, false)
  // }
  'zh-Hant': function(str) { // 简体转繁体
    return tranStr(str, true)
  },
  'zh-Hans': function(str) { // 繁体转简体
    return tranStr(str, false)
  }
}

// 转换成繁体
// const s2tResult = Chinese.s2t('那一天，人类终于回想起了，曾经一度被他们所支配的恐怖，还有被囚禁于鸟笼中的那份屈辱。');
// console.log(s2tResult)
//那一天，人類終於回想起了，曾經一度被他們所支配的恐怖，還有被囚禁於鳥籠中的那份屈辱。

// 转换成简体
// const s2tResult = Chinese.t2s('我們被教導記住思想，而不是人，因為人可能失敗，他可能會被捕，他會被殺死，被遺忘，但400年後，思想仍可改變世界，我親眼目睹了，思想的威力，我見過人們以它為名殺戮，或是為了它獻出生命，但你不能親吻思想，也不能觸摸它，或擁抱它，思想不會流血，不會感到痛苦，它們沒有愛！')
// console.log(s2tResult)
// 我们被教导记住思想，而不是人，因为人可能失败，他可能会被捕，他会被杀死，被遗忘，但400年后，思想仍可改变世界，我亲眼目睹了，思想的威力，我见过人们以它为名杀戮，或是为了它献出生命，但你不能亲吻思想，也不能触摸它，或拥抱它，思想不会流血，不会感到痛苦，它们没有爱！