"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";
import {
  getTotalDaysInYear,
  getDaysLeftInYear,
  getNextYear,
  getDiffInDays,
} from "@/utils/day";
import { Lunar, HolidayUtil } from "lunar-javascript";

export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);
  const curDate = dayjs(ymdStr);
  const curYear = curDate.year();
  const curMonth = curDate.month() + 1;
  const cnYmd = curDate.format("YYYY年M月D日");

  // 是否需要调休
  const isWork = HolidayUtil.getHoliday(curYear, curMonth, curDate.date());

  // 是否是工作日
  const isWorkDay = (() => {
    let result = "工作日";
    if (!isWork) {
      if (
        lunarData.weekIndex === "星期六" ||
        lunarData.weekIndex === "星期日"
      ) {
        result = "休息日";
      }
    }
    return result;
  })();

  // 节日
  const festivals = lunarData.solarFestivals.concat(lunarData.lunarFestivals);

  const totalDaysInYear = getTotalDaysInYear(ymdStr); // 获取本年总天数
  const daysLeftInYear = getDaysLeftInYear(ymdStr); // 当前日期距离本年总天数的第几天
  const nextYear = getNextYear(ymdStr); // 根据当前日期获取下一年的年份

  const nextYmd = `${nextYear}-01-01`;
  const nextDate = dayjs(nextYmd);
  const nextYearLunar = Lunar.fromDate(nextDate.toDate());

  // 下一年的春节
  const nextYearChunJie = (() => {
    let result = {};
    const nextYearTotalDaysInYear = getTotalDaysInYear(nextYmd);
    for (let i = 0; i < nextYearTotalDaysInYear; i++) {
      // 一直找到正月初一（这里的正月初一是农历的正月初一）春节只有农历正月初一才叫春节
      const lunarItem = nextYearLunar.next(i);
      const zhengYue1 =
        lunarItem.getMonthInChinese() + "月" + lunarItem.getDayInChinese();
      if (zhengYue1 === "正月初一") {
        result = {
          ...lunarItem,
        };
        break;
      }
    }
    return result;
  })();

  const nextYearChunJieSolar = nextYearChunJie.getSolar(); // 下一年农历春节的阳历
  const nextYearChunJieSolarYear = nextYearChunJieSolar.getYear(); // 下一年农历春节的阳历年
  const nextYearChunJieSolarMonth = nextYearChunJieSolar.getMonth(); // 下一年农历春节的阳历月
  const nextYearChunJieSolarDay = nextYearChunJieSolar.getDay(); // 下一年农历春节的阳历天

  // 起始时间和结束时间相差的天数
  const diffInDays = getDiffInDays(
    ymdStr,
    `${nextYearChunJieSolarYear}-${
      nextYearChunJieSolarMonth < 10
        ? "0" + nextYearChunJieSolarMonth
        : nextYearChunJieSolarMonth
    }-${
      nextYearChunJieSolarDay < 10
        ? "0" + nextYearChunJieSolarDay
        : nextYearChunJieSolarDay
    }`
  );

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd}是星期几`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="title">
            {Chinese[language]('今天是')}<span>{Chinese[language](lunarData.weekIndex)}</span>
          </div>
          <div className="content">
            <p>
              {Chinese[language](`${cnYmd}是${lunarData.weekIndex}，是`)}<span>{Chinese[language](isWorkDay)}</span>
            </p>
            <p>
              {cnYmd}是{curYear}年的<span>第{lunarData.weekOfYear}周</span>，
              {curMonth}月的
              <span>第{lunarData.weekOfMonth}周</span>
            </p>
            {festivals.length > 0 && (
              <p>
                {cnYmd}是<span>{festivals.join("、")}</span>
              </p>
            )}
            <p>
              {cnYmd}是{curYear}年的<span>第{daysLeftInYear}天</span>
            </p>
            <p>
              {Chinese[language](`距离${curYear}年结束还有`)}
              <span>{totalDaysInYear - daysLeftInYear}天</span>
            </p>
            <p>
              {Chinese[language](`距离${nextYear}年${nextYearChunJie.getYearInGanZhi()}年正月初一
              ${nextYearChunJie.getYearShengXiao()}年春节还有`)}
              <span>{diffInDays}</span>天
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
