"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";

export const yueLatitude = new Map();

export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);
  const cnYmd = dayjs(ymdStr).format("YYYY年M月D日");

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd}九星月相`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="content">
            <p><b>{Chinese[language]('今日九星：')}</b>{Chinese[language](lunarData.nine.dayNineStar)}。</p>
            <p><b>{Chinese[language]('今日月相：')}</b><span>{Chinese[language](`${lunarData.yue}月`)}</span></p>
            <p>{Chinese[language]('月相，是天文学中对于地球上看到的月球被太阳照明部分的称呼。随着月亮每天在星空中自东向西移动一大段距离，它的形状也在不断地变化着，这就是月亮位相变化，叫做月相。')}</p>
          </div>
        </div>
      </div>
    </div>
  );
});
