"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";

// comp
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from "antd";

export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);
  const year = dayjs(ymdStr).format("YYYY年");

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${year}灶马头`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="content">
            <p>
              <b>
                <Tooltip title={Chinese[language]('因子对应鼠，正月第1个子日是初几，就是几鼠偷粮，粮对应财富，老鼠越多，财富流失得越多；')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几鼠偷粮：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.touLiang)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('草子几分，子对应子日，指粮食收成')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('草子几分：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.caoZi)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('耕田用牛，牛对应丑，因此正月第1个丑日是初几，就是几牛耕田，牛多象征着收成好。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几牛耕田：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.gengTian)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('花影响养蜂人的收入，花对应卯，正月第1个卯日是初几，就是花收几分。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('花收几分：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.huaShou)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('治水靠龙，龙对应辰，因此正月第1个辰日是初几，就是几龙治水。民间有“龙多旱涝不均，龙少风调雨顺”的说法。类似三个和尚没水喝。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几龙治水：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.zhiShui)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('午对应马，正月第1个午日是初几，就是几马驮谷，马越多说明粮食收成越好。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几马驮谷：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.tuoGu)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('酉对应鸡，正月第1个酉日是初几，就是几鸡抢米，鸡抢米代表饥饿，抢米的鸡越多说明粮食收成不好。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几鸡抢米：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.qiangMi)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('鸡叫的声音是“咕咕咕”，谐音“姑”，因此也有几姑看蚕的说法。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几姑看蚕：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.kanCan)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('就是几个屠夫一起杀猪的意思。亥为猪，正月第1个亥日是初几，则为几屠共猪。共猪的屠夫越多，说明猪越少，猪肉越贵。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几屠共猪：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.gongZhu)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('正月第1个甲日是初几，则为甲田几分。甲田几分的意思是几户人家共同分一块甲田，尽管甲田上的农作物产量高，分甲田的人家越多，就意味着当年农民收获的粮食就越少，相反分甲田的人家越少，农民的收获的粮食就越多。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('甲田几分：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.jiaTian)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('丙与饼谐音，因此正月第1个丙日是初几，就是几人分饼。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几人分饼：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.fenBing)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('有诗云：“天龙治水望丰年，神牛下界好耕田；人少饼多吃喝厚，得辛金重有余钱”。得金，也叫得辛，正月第1个辛日是初几，就是几日得金。得金的日子越多，说明钱越好挣。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几日得金：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.deJin)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('人对应寅，正月第1个寅日是初几，就是几人。正月第1个丙日是初几，就是几丙。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几人几丙：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.renBing)}</span>
            </p>
            <p>
              <b>
                <Tooltip title={Chinese[language]('正月第1个丁日是初几，就是几锄。')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                &nbsp;{Chinese[language]('几人几锄：')}
              </b>
              <span>{Chinese[language](lunarData.zmt.renChu)}</span>
            </p>
            <p>
              {Chinese[language]('灶马头是一本奇书，是我国古人智慧的结晶。我国古代由于科技不发达，并不能像现在一样预测天气，所以很多人都是通过一些推断，来预测下一年年景的，慢慢的把这些经验汇集在一起，也就造就了这本书的诞生，一直传承下来，时至今日，很多地区的农村还有看这灶马头的习惯。')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
