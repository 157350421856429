"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// comp
import { Timeline, Tag } from "antd";

// css
import styles from "./index.module.scss";

// utils
import classNames from "classnames";
import getSolarFestival from "@/utils/tung-shing/get-solar-festival";
import getLunarFestival from "@/utils/tung-shing/get-lunar-festival";
import dayjs from "dayjs";

export default memo(() => {
  const { ymdStr, Chinese, language } = useContext(TungShingContext);
  const cnYmd = dayjs(ymdStr).format("YYYY年M月D日");
  const year = dayjs(ymdStr).year();
  const month = dayjs(ymdStr).month() + 1;
  const monthFestivals = []; // 本月所有节日节气

  // 获取当前月份的总天数
  const totalDaysInMonth = dayjs().daysInMonth();

  const holidayMap = new Map([]);

  for (let i = 1; i <= totalDaysInMonth; i++) {
    const ymdStr = `${year}-${month < 10 ? "0" + month : month}-${
      i < 10 ? "0" + i : i
    }`;
    const solarFestivals = getSolarFestival(ymdStr);
    const lunarFestivals = getLunarFestival(ymdStr);
    // const cnMd = `${month}月${i}日`;

    if (solarFestivals.length > 0) {
      holidayMap.set(ymdStr, []);

      for (const dayName of solarFestivals) {
        monthFestivals.push({
          label: ymdStr,
          children: dayName,
        });
      }
    }
    if (lunarFestivals.length > 0) {
      holidayMap.set(ymdStr, []);

      for (const dayName of lunarFestivals) {
        monthFestivals.push({
          label: ymdStr,
          children: dayName,
        });
      }
    }
  }

  for (const festivalItem of monthFestivals) {
    const { label, children } = festivalItem;
    holidayMap.set(label, [
      ...holidayMap.get(label),
      children,
    ]);
  }

  const timelines = [];

  holidayMap.forEach((value, key) => {
    const cnMd = dayjs(key).format('M月D日');

    let label = cnMd;

    let color = '#cb7a43';

    // 当前时间高亮
    if (ymdStr === key) {
      label = <span>{cnMd}</span>;
      color = 'var(--qm-color-primary)';
    }

    timelines.push({
      label,
      children: <>
        {value.map((val, idx) => <Tag key={idx}>{Chinese[language](val)}</Tag>)}
      </>,
      color,
    });
  })

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd.slice(0, 7)}所有节日节气`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="title">
            {month}月有<span>{monthFestivals.length}个</span>{Chinese[language](`节日节气`)}
          </div>
          <div
            className={classNames({
              ["content"]: true,
              [styles.container]: true,
            })}
          >
            <Timeline mode="left" items={timelines} />
          </div>
        </div>
      </div>
    </div>
  );
});
