// utils
import dayjs from 'dayjs';
import { Lunar } from 'lunar-javascript';

// 获取农历节日
export default function getLunarFestival (ymdStr = dayjs().format('YYYY-MM-DD')) {
  const curDate = dayjs(ymdStr);
  const curLunar = Lunar.fromDate(curDate.toDate());

  // 返回常用节日的数组，包括春节、中秋、元宵等，有可能同一天有多个，也可能没有
  const festivals = curLunar.getFestivals() || [];

  // 返回其他传统节日的数组，包括寒衣节、下元节、祭灶日等，有可能同一天有多个，也可能没有
  const otherFestivals = curLunar.getOtherFestivals() || [];

  // 节气
  const jieQi = curLunar.getJieQi();
  const jieQiArrs = jieQi ? [jieQi] : [];

  return festivals.concat(otherFestivals, jieQiArrs);
}