// utils
import dayjs from 'dayjs';
import DayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(DayOfYear);

// 获取本年总天数
export function getTotalDaysInYear (ymdStr) {
  // 获取当前日期
  const curDate = dayjs(ymdStr);

  // 获取年初日期
  const startOfYear = curDate.startOf('year');

  // 获取年末日期
  const endOfYear = curDate.endOf('year');

  // 获取本年总天数
  const totalDaysInYear = endOfYear.dayOfYear() - startOfYear.dayOfYear() + 1;

  return totalDaysInYear;
}

// 获取当前日期距离本年总天数的第几天
export function getDaysLeftInYear (ymdStr) {
  // 获取当前日期
  const curDate = dayjs(ymdStr);

  // 获取当前日期是本年的第几天
  const dayOfYear = curDate.dayOfYear();

  // 计算当前日期距离本年总天数的第几天
  const daysLeftInYear = dayOfYear;

  return daysLeftInYear;
}

// 根据当前日期获取下一年的年份
export function getNextYear (ymdStr) {
  // 获取当前日期
  const curDate = dayjs(ymdStr);

  // 获取下一年的年份
  const nextYear = curDate.add(1, 'year').year();

  return nextYear;
}

// 起始时间和结束时间相差的天数
export function getDiffInDays(startYmdStr, endYmdStr) {
  const start = dayjs(startYmdStr);
  const end = dayjs(endYmdStr);
  const diffDays = end.diff(start, 'day');
  return diffDays;
}