// utils
import dayjs from 'dayjs';
import { Lunar } from 'lunar-javascript';

// 获取天干地支
export default function getGanZhi (ymdStr = dayjs().format('YYYY-MM-DD')) {
  const curDate = dayjs(ymdStr);
  const curLunar = Lunar.fromDate(curDate.toDate());

  return {
    // 以立春当天起
    yearGanZhiByLiChun: curLunar.getYearGanByLiChun(), // 年干
    yearZhiByLiChun: curLunar.getYearZhiByLiChun(), // 年支
    yearInGanZhiByLiChun: curLunar.getYearInGanZhiByLiChun(), // 年干支

    // 以节交接当天起
    monthGan: curLunar.getMonthGan(), // 月干
    monthZhi: curLunar.getMonthZhi(), // 月支
    monthInGanZhi: curLunar.getMonthInGanZhi(), // 月干支

    dayGan: curLunar.getDayGan(), // 日干
    dayZhi: curLunar.getDayZhi(), // 日支
    dayInGanZhi: curLunar.getDayInGanZhi(), // 日干支
  };
}