"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";

export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);
  const cnYmd = dayjs(ymdStr).format('YYYY年M月D日');

  const dayLuckStr = lunarData.tianshen.dayTianShenLuck;
  // 今天是否是好日子
  const isDayLuck = dayLuckStr === "吉";

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd}黄历是吉日吗`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="title">
            今天<span>是{dayLuckStr}日</span>，{isDayLuck ? '是好日子' : '不是好日子'}
          </div>
          <div className="content">
            今日值神是<span>{Chinese[language](lunarData.tianshen.dayTianShen)}</span>，是<span>{Chinese[language](lunarData.tianshen.dayTianShenType)}日</span>
            ，{Chinese[language](`因此在黄历上${isDayLuck ? '是个好日子' : '不是好日子'}`)}。
          </div>
        </div>
      </div>
    </div>
  );
});
