// 浅度删除数组重复项
export function uniq(arr) {
  // 用于存放去重后的唯一值及其索引的数组
  let uniqueValues = [];
  // 用于存放重复的值及其索引的数组
  let duplicates = [];

  // 遍历输入数组
  arr.forEach((item, index) => {
    // 检查当前值是否已存在于 uniqueValues 数组中
    const existingIndex = uniqueValues.findIndex(valueObj => valueObj.value === item);
    if (existingIndex === -1) {
      // 如果当前值不存在于 uniqueValues 数组中，则将其添加到 uniqueValues 数组中，并记录索引
      uniqueValues.push({ value: item, indexes: [index] });
    } else {
      // 如果当前值已存在于 uniqueValues 数组中，则将其索引记录到对应的对象中，并将其添加到 duplicates 数组中
      uniqueValues[existingIndex].indexes.push(index);
      duplicates.push({ value: item, indexes: [index] });
    }
  });

  // 返回去重后的唯一值数组和重复值数组
  return {
    unique: uniqueValues,
    duplicates: duplicates
  };
}

// 示例用法
// const array = [1, 2, 3, 3, 4, 5, 5, 6];
// const result = uniq(array);
// console.log("去重后的数组:", result.unique);
// console.log("重复的值数组:", result.duplicates);
