"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";
import { Lunar } from 'lunar-javascript';

// styles
import styles from './index.module.scss';

// 建除十二星
export default memo(() => {
  const { ymdStr, cnYmd, Chinese, language } = useContext(TungShingContext);
  const year = dayjs(ymdStr).year();
  const month = dayjs(ymdStr).month() + 1;

  // 获取当前月份的总天数
  const totalDaysInMonth = dayjs().daysInMonth();

  const zhiXingMap = new Map([]);

  for (let i = 1; i <= totalDaysInMonth; i++) {
    const ymdStr = `${year}-${month < 10 ? '0' + month : month}-${i < 10 ? '0' + i : i}`;
    const curDate = dayjs(ymdStr);
    const curLunar = Lunar.fromDate(curDate.toDate());
    const dayName = curLunar.getZhiXing();
    if (!Array.isArray(zhiXingMap.get(dayName))) {
      zhiXingMap.set(dayName, []);
    }
    zhiXingMap.set(dayName, [
      ...zhiXingMap.get(dayName),
      `${month}月${curDate.date()}日`,
    ]);
  }

  return <div className="infoitem">
    <h3>{Chinese[language](`${cnYmd.slice(0, 7)}十二建日查询`)}</h3>
    <div className="body">
      <div className="wrap">
        <div className="content">

          <div className={styles.container}>
            <div className={styles.list}>
              <div className={styles.row}>
                <div className='left'>{month}{Chinese[language](`月建日`)}</div>
                <div className='right'>{zhiXingMap.get('建')?.join('、')}</div>
              </div>

              <div className={styles.row}>
                <div className='left'>{month}{Chinese[language](`月平日`)}</div>
                <div className='right'>{zhiXingMap.get('平')?.join('、')}</div>
              </div>

              <div className={styles.row}>
                <div className='left'>{month}{Chinese[language](`月定日`)}</div>
                <div className='right'>{zhiXingMap.get('定')?.join('、')}</div>
              </div>

              <div className={styles.row}>
                <div className='left'>{month}{Chinese[language](`月执日`)}</div>
                <div className='right'>{zhiXingMap.get('执')?.join('、')}</div>
              </div>

              <div className={styles.row}>
                <div className='left'>{month}{Chinese[language](`月破日`)}</div>
                <div className='right'>{zhiXingMap.get('破')?.join('、')}</div>
              </div>

              <div className={styles.row}>
                <div className='left'>{month}{Chinese[language](`月危日`)}</div>
                <div className='right'>{zhiXingMap.get('危')?.join('、')}</div>
              </div>

              <div className={styles.row}>
                <div className='left'>{month}{Chinese[language](`月成日`)}</div>
                <div className='right'>{zhiXingMap.get('成')?.join('、')}</div>
              </div>

              <div className={styles.row}>
                <div className='left'>{month}{Chinese[language](`月收日`)}</div>
                <div className='right'>{zhiXingMap.get('收')?.join('、')}</div>
              </div>

              <div className={styles.row}>
                <div className='left'>{month}{Chinese[language](`月开日`)}</div>
                <div className='right'>{zhiXingMap.get('开')?.join('、')}</div>
              </div>

              <div className={styles.row}>
                <div className='left'>{month}{Chinese[language](`月闭日`)}</div>
                <div className='right'>{zhiXingMap.get('闭')?.join('、')}</div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>;
});