"use client";

// hooks
import { memo, useState, useContext, useEffect } from "react";
import { useUpdateEffect, useMemoizedFn, useSessionStorageState } from "ahooks";
import { useRouter } from "next/navigation";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import { HolidayUtil } from "lunar-javascript";
import Calendar from "@/utils/calendar";
import dayjs from "dayjs";
import classNames from "classnames";
import { dayNames, dayMap } from "@/utils/tung-shing/get-goodday";
import { Chinese } from "@/utils/chinese-s2t";

// comp
import { Select } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import Link from "next/link";

// css
import styles from "./index.module.scss";
import holidayStyles from "@/components/tung-shing/holiday/index.module.scss";

// 周头
const weekHead = ["日", "一", "二", "三", "四", "五", "六"];

// 年份
const yearList = [];
for (let i = 1900; i < 2100; i++) {
  yearList.push({
    value: i,
    label: i + "年",
  });
}

// 月份
const monthList = [];
for (let i = 1; i <= 12; i++) {
  monthList.push({
    value: i,
    label: i + "月",
  });
}

// 初始化日期
const initCalendar = (ymdStr) => {
  return new Calendar({
    toDate: dayjs(ymdStr).toDate(),
  }).calendar;
};

export default memo(() => {
  const { ymdStr, language } = useContext(TungShingContext);

  const ymdShort = dayjs(ymdStr).format("YYYY-M-D");
  const GOODDAY_KEY = `calendar-goodday`;
  const FESTIVAL_KEY = `calendar-festival`;

  const [curYear, setCurYear] = useState(dayjs(ymdStr).year()); // 当前年份
  const [curMonth, setCurMonth] = useState(dayjs(ymdStr).month() + 1); // 当前月份

  const [, setCurGoodDayStorage] = useSessionStorageState(GOODDAY_KEY); // 当前吉日Storage
  const [curGoodDay, setCurGoodDay] = useState(); // 当前吉日

  const [, setCurFestivalStorage] = useSessionStorageState(FESTIVAL_KEY); // 当前假期Storage
  const [curFestival, setCurFestival] = useState(); // 当前假期

  // 初始化日期数据
  const [dateList, setDateList] = useState(initCalendar(ymdStr));

  // 择吉日
  const handleGoodDayChange = useMemoizedFn((value) => {
    setCurGoodDay(value);
    setCurGoodDayStorage(value);
  });

  // 假期安排
  const [holidayList, setHolidayList] = useState([
    {
      value: "yuandan",
      label: "元旦节",
    },
    {
      value: "chunjie",
      label: "春节",
    },
    {
      value: "qingming",
      label: "清明节",
    },
    {
      value: "laodong",
      label: "劳动节",
    },
    {
      value: "duanwu",
      label: "端午节",
    },
    {
      value: "zhongqiu",
      label: "中秋节",
    },
    {
      value: "guoqing",
      label: "国庆节",
    },
  ]);

  // 根据年份获取对应的放假日期
  useEffect(() => {
    setCurGoodDay(sessionStorage.getItem(GOODDAY_KEY)?.replace(/"/gi, ""));
    setCurFestival(sessionStorage.getItem(FESTIVAL_KEY)?.replace(/"/gi, ""));

    const holidays = HolidayUtil.getHolidays(curYear);

    for (const holidayItem of holidayList) {
      const { label } = holidayItem;
      for (let i = 0; i < holidays.length; i++) {
        const dayItem = holidays[i];
        const name = dayItem.getName();
        const startYmd = dayItem.getDay();
        if (label === name) {
          holidayItem.value = startYmd;
          break;
        }
      }
    }

    setHolidayList([...holidayList]);
  }, [curYear]);

  // 年份选择
  const handleYearChange = useMemoizedFn((year) => {
    setCurYear(year);
  });

  // 月份选择
  const handleMonthChange = useMemoizedFn((month) => {
    setCurMonth(month);
    setCurFestival(null);
    setCurFestivalStorage(undefined);
  });

  // 第一次不会触发下面的逻辑
  // 只有在curYear、curMonth第二次更新的时候触发
  useUpdateEffect(() => {
    const newYmd = `${curYear}-${curMonth < 10 ? "0" + curMonth : curMonth}-01`;
    setDateList(initCalendar(newYmd));
  }, [curYear, curMonth]);

  // 第一次不会触发下面的逻辑
  // 只有在ymdStr第二次更新的时候触发
  useUpdateEffect(() => {
    setDateList(initCalendar(ymdStr)); // 重新初始化日期
  }, [ymdStr]);

  // 选择假期
  const handleFestivalChange = useMemoizedFn((ymdStr) => {
    const curDate = dayjs(ymdStr);
    setDateList(initCalendar(ymdStr));
    setCurFestival(ymdStr);
    setCurYear(curDate.year());
    setCurMonth(curDate.month() + 1);

    setCurFestivalStorage(ymdStr);
  });

  const router = useRouter();

  // 回到今天
  const handleToCurrentTime = useMemoizedFn(() => {
    const curDate = dayjs();
    const year = curDate.year();
    const month = curDate.month() + 1;
    const day = curDate.date();
    setDateList(initCalendar(curDate.format("YYYY-MM-DD")));
    setCurYear(year);
    setCurMonth(month);
    setCurFestival(null);
    setCurFestivalStorage(undefined);
    router.push(`/${year}-${month}-${day}.html`);
    // window.location.href = `./${year}-${month}-${day}.html`; // 适配php/静态跳转
  });

  // 切换日期
  // const handleDateChange = useMemoizedFn((odate) => {
  //   const {
  //     solarYear,
  //     solarMonth,
  //     solarDay,
  //   } = odate;
  //   router.push(`/${solarYear}-${solarMonth}-${solarDay}`);
  //   // window.location.href = `./${solarYear}-${solarMonth}-${solarDay}.html`; // 适配php/静态跳转
  // });

  // 放假通知滚动到底部
  const handleToHoliday = useMemoizedFn(() => {
    window.scrollTo({
      top: document.querySelector(`.${holidayStyles.vacation}`).offsetTop,
      behavior: "smooth", // 可选：平滑滚动
    });
  });

  return (
    <div className={styles.container}>
      <h2 className={styles.head}>
        <div className={styles.yearlist}>
          <Select
            showSearch
            options={yearList}
            style={{ width: 100 }}
            placeholder="选择年份"
            value={curYear}
            onChange={handleYearChange}
          />
        </div>
        <div className={styles.monthlist}>
          <Select
            showSearch
            options={monthList}
            style={{ width: 100 }}
            placeholder="选择月份"
            value={curMonth}
            onChange={handleMonthChange}
          />
        </div>
        <div className={styles.gooddaylist}>
          <Select
            showSearch
            allowClear
            options={dayNames.map((item) => {
              item.label = Chinese[language](item.label);
              return item;
            })}
            style={{ width: 100 }}
            placeholder={Chinese[language]("吉日查询")}
            value={curGoodDay}
            onChange={handleGoodDayChange}
          />
        </div>
        <div className={styles.holidaylist}>
          <Select
            showSearch
            options={holidayList.map((item) => {
              item.label = Chinese[language](item.label);
              return item;
            })}
            style={{ width: 100 }}
            placeholder={Chinese[language]("假期查询")}
            value={curFestival}
            onChange={handleFestivalChange}
          />
        </div>
        <div className={styles.today} onClick={handleToCurrentTime}>
          <span>返回今天</span>
        </div>
        <div className={styles.extra} onClick={handleToHoliday}>
          <span>{curYear}年放假安排</span>
          <ExportOutlined />
        </div>
      </h2>

      <ul className={styles.week}>
        {weekHead.map((week, index) => (
          <li key={index}>{week}</li>
        ))}
      </ul>
      <div className={styles.datelist}>
        {dateList.map((odate, index) => {
          const {
            solarYear,
            solarMonth,
            solarDay,
            festivals,
            lunarDay,
            isCurrentDate,
            position,
            holiday,
            yiji: { yi },
          } = odate;

          const curGoodDayName = dayMap.get(curGoodDay);
          const isGoodDay = yi.includes(curGoodDayName);

          const solarYmd = `${solarYear}-${solarMonth}-${solarDay}`;

          return (
            <Link
              className={classNames({
                [styles.date]: true,
                [styles.current]: isCurrentDate,
                [styles.before]: position === "before",
                [styles.after]: position === "after",
                [styles.holiday]: holiday && holiday.isWork === false, // 休息天
                [styles.work]: holiday && holiday.isWork === true, // 补班
                [styles.goodday]: isGoodDay,
                [styles.active]: ymdShort === solarYmd,
              })}
              key={index}
              href={`/${solarYmd}.html`}
            >
              <p>{solarDay}</p>
              <p>
                {Chinese[language](festivals[0] || lunarDay)}
                {isGoodDay ? (
                  <>
                    /
                    {curGoodDay !== "yuShi" && curGoodDay !== "zhuShi"
                      ? "宜"
                      : ""}
                    {curGoodDayName}
                  </>
                ) : null}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
});
