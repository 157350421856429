"use client";

// hooks
import { memo, useContext } from "react";

// css
import styles from "./index.module.scss";

// comp
// import { Typography } from "antd";

// utils
import { TungShingContext } from "@/components/tung-shing/provider";

// const { Paragraph } = Typography;

// 历史今天/某一天
export default memo((props) => {
  const { oneDayData, Chinese, language } = useContext(TungShingContext);

  const {
    festival = [], // 节日
    memorabilia = [], // 大事件
    birthCelebrity = [], // 出生名人
    dieCelebrity = [], // 逝世名人
  } = props?.result || oneDayData?.result || {};

  return (
    <div className={styles.container}>
      {festival.length > 0 && <div className={styles.title}>{Chinese[language]('节日')}</div>}
      {festival.map(({ name, content }, index) => (
        <div className={styles.holiday} key={index}>
          <div className={styles.subtitle}>{Chinese[language](name)}</div>
          <div className={styles.desc}>
            {Chinese[language](content)}
            {/* <Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: "更多",
              }}
            >
              {content}
            </Paragraph> */}
          </div>
        </div>
      ))}

      {memorabilia.length > 0 && (
        <>
          <div className={styles.title}>{Chinese[language]('大事件')}</div>
          <div className={styles.list}>
            {memorabilia.map(({ year, content }, index) => (
              <div className={styles.row} key={index}>
                <div className="left">{year.trim()}</div>
                <div className="right">{Chinese[language](content)}</div>
              </div>
            ))}
          </div>
        </>
      )}

      {birthCelebrity.length > 0 && (
        <>
          <div className={styles.title}>{Chinese[language]('出生名人')}</div>
          <div className={styles.list}>
            {birthCelebrity.map(({ year, content }, index) => (
              <div className={styles.row} key={index}>
                <div className="left">{year.trim()}</div>
                <div className="right">{Chinese[language](content)}</div>
              </div>
            ))}
          </div>
        </>
      )}

      {dieCelebrity.length > 0 && (
        <>
          <div className={styles.title}>{Chinese[language]('逝世名人')}</div>
          <div className={styles.list}>
            {dieCelebrity.map(({ year, content }, index) => (
              <div className={styles.row} key={index}>
                <div className="left">{year.trim()}</div>
                <div className="right">{Chinese[language](content)}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
});
