// utils
import dayjs from 'dayjs';
import { SolarYear, HolidayUtil, Lunar } from 'lunar-javascript';

export default class Calendar {
  constructor (options) {
    const results = this.createTime(options?.toDate ?? dayjs().toDate());

    this.calendar = results.flat(Infinity).filter(item => item.solarDay !== '');
  }

  createTime (toDate) {
    // 日历数组
    let dates = [];

    // 存储天数
    const days = [];

    // 获取当年现在的月份，注意：月份的索引为0
    const getCurrentMonth = dayjs(toDate).month();

    // 获取当年总月份实例
    const getCurrentYearMonths = SolarYear.fromDate(toDate).getMonths();

    // 获取当月总天数实例
    const getCurrentMonthDays = getCurrentYearMonths[getCurrentMonth].getDays();

    // 上个月的占位天数
    const getPrevMonthDayPlaceholder = this.getPrevMonthDayPlaceholder(toDate, getCurrentMonthDays[0].getWeek());

    // 下个月的占位天数
    const getNextMonthDayPlaceholder = this.getNextMonthDayPlaceholder(toDate, getCurrentMonthDays[0].getWeek());

    for (const day of getCurrentMonthDays) {
      const dLunar = day.getLunar();

      const params = this.createDateParams(day, dLunar, 'current');

      days.push(params);
    }

    // 装填日期
    dates = [
      ...getPrevMonthDayPlaceholder,
      ...days,
      ...getNextMonthDayPlaceholder
    ];

    return dates;
  }

  /**
   * 获取上一个月的日子
   * @param toDate 时间实例
   * @param week   获取上月天数以及本月一号开始是星期几，这样就能知道上月日子占位
  */
  getPrevMonthDayPlaceholder (toDate, week) {
    const result = [];

    // 上一个月
    const prevMonth = dayjs(toDate).subtract(1, 'month');

    // 获取这个月份所在年份的总月份实例
    const getMonths = SolarYear.fromDate(dayjs(prevMonth).toDate()).getMonths();

    // 当前月份
    const currentMonthDay = getMonths[prevMonth.month()].getDays();

    for (let i = 0; i < week; i++) {
      // 获取上个月每一天的实例
      const day = currentMonthDay[(currentMonthDay.length - 1) - i];

      const dLunar = day.getLunar();

      const params = this.createDateParams(day, dLunar, 'before');

      result.unshift(params);
    }

    return result;
  }

  /**
   * 获取下一个月的日子
   * @param toDate  时间实例
   * @param week    获取下月天数以及本月一号开始是星期几，这样就能知道下月日子占位
  */
  getNextMonthDayPlaceholder (toDate, week) {
    const result = [];

    // 下一个月
    const nextMonth = dayjs(toDate).add(1, 'month');

    // 获取这个月份所在年份的总月份实例
    const getMonths = SolarYear.fromDate(dayjs(nextMonth).toDate()).getMonths();

    // 当前月份
    const currentMonthDay = getMonths[nextMonth.month()].getDays();

    // 当月月份总天数
    const daysInMonth = dayjs(toDate).daysInMonth();

    // 有种特殊情况就是1号是星期天，week为0，而上月天数占比需要为7，所以就需要+7，要不然会出现问题
    const emptyDays = (week == 0 ? 7 : week);

    // (6*7=42）42减去上月占位天数和本月天数，剩下的位置就是下个月的天数
    const sumToday = 42;
    let after = sumToday - daysInMonth - emptyDays;

    // 行不能少于6行，少于6行补上下个月日子
    if (after <= 5) {
      after = sumToday - daysInMonth;
    }

    for (let i = 0; i < after; i++) {
      // 获取下个月每一天的实例
      const day = currentMonthDay[i]

      const dLunar = day.getLunar();

      const params = this.createDateParams(day, dLunar, 'after');

      result.push(params);
    }

    return result;
  }

  /**
   * 生成节假日
   * @param solarDay 阳历
   * @param lunarDay 农历
  */
  getFestivals (solarDay, lunarDay) {
    const result = [];

    const solarFestivals = solarDay.getFestivals();
    const lunarFestivals = lunarDay.getFestivals();
    const lunarJieQi = lunarDay.getJieQi();

    // 农历传统节日
    if (lunarFestivals) {
      result.push(...lunarFestivals)
    }

    // 阳历传统节日
    if (solarFestivals) {
      result.push(...solarFestivals)
    }

    // 节气
    if (lunarJieQi) {
      result.push(lunarJieQi)
    }

    return result;
  }

  /**
   * 获取每日宜忌
   * @param ymd 年月日
  */
  getYiJi(ymd) {
    const lunar = Lunar.fromDate(dayjs(ymd).toDate());

    return {
      yi: lunar.getDayYi(),
      ji: lunar.getDayJi(),
    }
  }

  /**
   * 获取每个月对应的季节
   * @param month
  */
  getMonthJijie (month) {
    let result = null;

    if (month === 12 || month === 1 || month === 2) {
      result = '冬季';
    }

    if (month === 3 || month === 4 || month === 5) {
      result = '春季';
    }

    if (month === 6 || month === 7 || month === 8) {
      result = '夏季';
    }

    if (month === 9 || month === 10 || month === 11) {
      result = '秋季';
    }

    return result;
  }

  /**
   * 创建日期信息
   * @param solarDay 阳历天实例
   * @param lunarDay 阴历天实例
   * @param position 当天所在的位置（上个月、当前月、下个月）
  */
  createDateParams (solarDay, lunarDay, position) {
    // 年月日
    const ymd = solarDay.toYmd();

    // 节假日放假
    const holiday = HolidayUtil.getHoliday(ymd);

    const params = {
      week: solarDay.getWeek(),
      solarYear: solarDay.getYear(),
      solarMonth: solarDay.getMonth(),
      solarDay: solarDay.getDay(),
      xingzuo: solarDay.getXingZuo(),
      jijie: this.getMonthJijie(lunarDay.getMonth()),
      festivals: this.getFestivals(solarDay, lunarDay),
      position: position,
      yiji: this.getYiJi(ymd),
      lunarDay: lunarDay.getDayInChinese(),
      lunarMonth: lunarDay.getMonth(),
      isCurrentDate: dayjs().isSame(dayjs(ymd), 'day'),
      lunarDetails: {
        monthInChinese: `${lunarDay.getMonthInChinese()}月`,
        dayInChinese: lunarDay.getDayInChinese(),
        shengxiao: lunarDay.getYearShengXiao(),
        dayShengXiao: lunarDay.getDayShengXiao(),
        chongsha: `${lunarDay.getChongDesc()} 煞${lunarDay.getSha()}`,
        yearNaYin: lunarDay.getYearNaYin(),
        monthNaYin: lunarDay.getMonthNaYin(),
        dayNaYin: lunarDay.getDayNaYin(),
        xingxiu: `${lunarDay.getXiu()}${lunarDay.getAnimal()}${lunarDay.getZheng()}`,
        liuyao: lunarDay.getLiuYao(),
        zhixing: `${lunarDay.getZhiXing()}日`,
        zhishen: lunarDay.getDayTianShen(),
        pengzu: `${lunarDay.getPengZuGan()} ${lunarDay.getPengZuZhi()}`,
        caishen: lunarDay.getPositionCaiDesc(),
        taishen: lunarDay.getDayPositionTai(),
        yearGan: lunarDay.getYearGan(),
        yearZhi: lunarDay.getYearZhi(),
        monthInGanZhi: lunarDay.getMonthInGanZhi(),
        dayInGanZhi: lunarDay.getDayInGanZhi(),
        weekInChinese: lunarDay.getSolar().getWeekInChinese(),
      }
    }

    // 每个月初一的时候显示当月所在月份
    if (lunarDay.getDay() === 1) {
      params.lunarDay = `${lunarDay.getMonthInChinese()}月`;
    }

    if (lunarDay) {
      // 放假节日
      params.holiday = {
        // 节日是否有补班，true就是需要，false就是不需要
        isWork: holiday?.isWork?.()
      }
    }

    return params;
  }
}