"use client";

// hooks
import { memo, useContext, useEffect, useState } from "react";
import { useMemoizedFn } from "ahooks";

// comp
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Link from 'next/link';

// css
import styles from "./index.module.scss";
import yijiStyles from "@/components/tung-shing/table/index.module.scss";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import classNames from "classnames";
import dayjs from "dayjs";
import IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import IsSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(IsSameOrAfter);
dayjs.extend(IsSameOrBefore);

// 农历
export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);

  // 时辰宜忌
  const [timeList, setTimeList] = useState(lunarData.timeList);

  useEffect(() => {
    // 当前时间
    setTimeList(timeList.map((item) => {
      const { startTime, endTime } = item;

      // 检查一个日期是否等于或者大于一个日期
      const isSameOrAfter = dayjs().isSameOrAfter(`${ymdStr} ${startTime}:00`);

      // 检查一个日期是否等于或者小于一个日期
      const isSameOrBefore = dayjs().isSameOrBefore(`${ymdStr} ${endTime}:00`);

      // 当前时间对应时辰高亮
      if (isSameOrAfter && isSameOrBefore) {
        item.active = true;
      }

      return item;
    }));
  }, [ymdStr]);

  // const router = useRouter();

  // 点击跳转上一天
  // const handlePrevDate = useMemoizedFn(() => {
  //   const prevDate = dayjs(ymdStr).subtract(1, 'day');
  //   router.push(`/${prevDate.format('YYYY-M-D')}`);
  //   // window.location.href = `./${prevDate.format('YYYY-M-D')}.html`; // 适配php/静态跳转
  // });

  // 点击跳转下一天
  // const handleNextDate = useMemoizedFn(() => {
  //   const nextDate = dayjs(ymdStr).add(1, 'day');
  //   router.push(`/${nextDate.format('YYYY-M-D')}`);
  //   // window.location.href = `./${nextDate.format('YYYY-M-D')}.html`; // 适配php/静态跳转
  // });

  // 查看更多宜忌
  const handleToYiJi = useMemoizedFn(() => {
    window.scrollTo({
      top: document.querySelector(`.${yijiStyles.tungshing}`).offsetTop,
      behavior: 'smooth'  // 可选：平滑滚动
    });
  });

  return (
    <div className={styles.container}>
      {/*今日较好的日子*/}
      <div className={styles.good}>
        <div className={styles.head}>
          <div className={styles.top}>
            <div className={styles.badge}>{Chinese[language]('宜')}</div>
          </div>
          <div className={styles.bottom}>
            <ul>
              {lunarData.yiji?.yi?.map((text, index) => {
                return <li key={index}>{Chinese[language](text)}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.title}>
            <span>{Chinese[language](`胎神：${lunarData.taishen}`)}</span>
          </div>
          <div className={styles.column}>
            <div className={styles.left}>
              <h4>{Chinese[language]('吉神宜趋')}</h4>
              <ul>
                {lunarData.jishenxiongsha.dayJiShen.map((text, index) => (
                  <li key={index}>{Chinese[language](text)}</li>
                ))}
              </ul>
            </div>
            <div className={styles.right}>
              <h4>{Chinese[language]('财神位')}</h4>
              <ul>
                <li>{Chinese[language]('阳贵神：')}{Chinese[language](lunarData.jishen.dayYangGui)}</li>
                <li>{Chinese[language]('阴贵神：')}{Chinese[language](lunarData.jishen.dayYinGui)}</li>
                <li>&#8195;{Chinese[language]('喜神：')}{Chinese[language](lunarData.jishen.dayXi)}</li>
                <li>&#8195;{Chinese[language]('福神：')}{Chinese[language](lunarData.jishen.dayFu)}</li>
                <li>&#8195;{Chinese[language]('财神：')}{Chinese[language](lunarData.jishen.dayCai)}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*居中展示*/}
      <div className={styles.carry}>
        <div className={styles.solardate}>
          {Chinese[language](`公历 ${lunarData.solarYmd.year}年${lunarData.solarYmd.month}月${lunarData.solarYmd.day}日 ${lunarData.weekIndex} 第${lunarData.weekOfYear}周`)}
          {lunarData.solarFestivals.length > 0 && (
            <>（{Chinese[language](lunarData.solarFestivals[0])}）</>
          )}
        </div>
        <div className={styles.solarday}>
          <Link className={styles.prev} href={`/${dayjs(ymdStr).subtract(1, 'day').format('YYYY-M-D')}.html`}>
            <LeftOutlined />
          </Link>
          <div className={styles.num}>{lunarData.solarYmd.day}</div>
          <Link className={styles.next} href={`/${dayjs(ymdStr).add(1, 'day').format('YYYY-M-D')}.html`}>
            <RightOutlined />
          </Link>
        </div>
        <div className={styles.lunardate}>
          {Chinese[language](`农历 ${lunarData.lunarYmd.year}年 ${lunarData.shengxiao.yearShengXiaoByLiChun}年 ${lunarData.lunarYmd.month}月${lunarData.lunarYmd.day}`)} {lunarData.lunarFestivals.length > 0 && (
            <>（{Chinese[language](lunarData.lunarFestivals[0])}）</>
          )}
        </div>
        <div className={styles.pengzu}>
          <p>
            {Chinese[language](`${lunarData.ganzhi.yearInGanZhiByLiChun}年（${lunarData.shengxiao.yearShengXiaoByLiChun}）${lunarData.ganzhi.monthInGanZhi}月（${lunarData.shengxiao.monthShengXiao}）${lunarData.ganzhi.dayInGanZhi}日（${lunarData.shengxiao.dayShengXiao}）`)}
          </p>
          <p>
            {Chinese[language](`彭祖百忌：${lunarData.pengzu.tiangan} ${lunarData.pengzu.dizhi}`)}
          </p>
        </div>
        <div className={styles.hours}>
          <div className={styles.label}>{Chinese[language]('时辰宜忌')}</div>
          <div className={styles.value}>
            <ul>
              {timeList.map(({ ganzhi, tianshenLuck, active }, index) => (
                <li key={index} className={classNames({
                  active,
                })}>
                  {Chinese[language](ganzhi)}
                  <span>{Chinese[language](tianshenLuck)}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.label} onClick={handleToYiJi}>
            {Chinese[language]('查看更多')}
          </div>
        </div>
        <div className={styles.extra}>
          {Chinese[language](`今日值神是${lunarData.tianshen.dayTianShen}，是${lunarData.tianshen.dayTianShenType}日，因此在黄历上`)}
          {lunarData.tianshen.dayTianShenLuck === "吉" ? (
            <span className="good">{Chinese[language]('是个好日子')}</span>
          ) : (
            <span className="bad">{Chinese[language]('不是好日子')}</span>
          )}
        </div>
        <div className={styles.note}>
          {Chinese[language]('提示：黄道吉日、黑道日只是起一个参考作用，在择日时还应多加注意日子的宜忌')}
        </div>
      </div>
      {/*今日不好的日子*/}
      <div className={styles.bad}>
        <div className={styles.head}>
          <div className={styles.top}>
            <div className={styles.badge}>{Chinese[language]('忌')}</div>
          </div>
          <div className={styles.bottom}>
            <ul>
              {lunarData.yiji?.ji?.map((text, index) => {
                return <li key={index}>{Chinese[language](text)}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.title}>
            <span>
              {Chinese[language](`五行：${lunarData.nayin.dayNaYin} ${lunarData.zhixing}执位`)}
            </span>
          </div>
          <div className={styles.column}>
            <div className={styles.left}>
              <h4>{Chinese[language]('凶煞宜忌')}</h4>
              <ul>
                {lunarData.jishenxiongsha.dayXiongSha.map((text, index) => (
                  <li key={index}>{Chinese[language](text)}</li>
                ))}
              </ul>
            </div>
            <div className={styles.right}>
              <h4>{Chinese[language]('冲煞')}</h4>
              <ul>
                <li>
                  {Chinese[language](`日冲：${lunarData.chongsha.dayChongDesc.replace("(", "（").replace(")", "）")}`)}
                </li>
                <li>
                  {Chinese[language](`日煞：${lunarData.chongsha.daySha}`)}&#8195;&#8195;&#8195;&#8194;
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
