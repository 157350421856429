"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// css
import styles from "./index.module.scss";

// utils
import dayjs from "dayjs";
import { HolidayUtil } from "lunar-javascript";
import classNames from 'classnames';

export default memo(() => {
  const { ymdStr, Chinese, language } = useContext(TungShingContext);
  const year = dayjs(ymdStr).year();
  const curYear = dayjs().year();

  let holidays = HolidayUtil.getHolidays(year);
  let hasHolidays = true; // 本年是否有放假安排

  // 如果没有数据择选择本年的放假安排
  if (holidays.length <= 0) {
    holidays = HolidayUtil.getHolidays(curYear);
    hasHolidays = false; // 该年份没有假期安排
  }

  const holidayMap = new Map([]);

  for (const holiday of holidays) {
    const name = holiday.getName();
    const startDate = holiday.getDay();
    const startTarget = holiday.getTarget();
    const isWork = holiday.isWork();

    if (!holidayMap.get(name)) {
      holidayMap.set(name, []);
    }

    holidayMap.set(name, [
      ...holidayMap.get(name),
      {
        startDate,
        startTarget,
        isWork,
      },
    ]);
  }

  return (
    <div className={classNames({
      'infoitem': true,
      [styles.vacation]: true,
    })}>
      <h3>{Chinese[language](`${year}年节假日安排`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="title">
            {Chinese[language](`${hasHolidays ? `以下为${year}年放假安排` : `${year}年的数据尚未更新，以下为${curYear}年放假安排`}`)}
          </div>
          <div className="content">
            <div className={styles.container}>
              <div className={styles.head}>
                <div className={styles.item}>{Chinese[language]('节日')}</div>
                <div className={styles.item}>{Chinese[language]('放假时间')}</div>
                <div className={styles.item}>{Chinese[language]('调休日期')}</div>
                <div className={styles.item}>{Chinese[language]('放假天数')}</div>
              </div>

              <div className={styles.body}>
                {[...holidayMap.keys()].map((dayName, index) => {
                  const curHolidays = holidayMap.get(dayName);

                  const fangJias = curHolidays.filter(({ isWork }) => !isWork); // 放假
                  const tiaoXius = curHolidays.filter(({ isWork }) => isWork); // 调休

                  const sd = fangJias[0]?.startDate || curHolidays[0].startTarget;
                  const lastSd = fangJias[fangJias.length - 1]?.startDate || curHolidays[curHolidays.length - 1].startTarget;

                  return (
                    <div className={styles.row} key={index}>
                      <div className={styles.col}>{Chinese[language](dayName)}</div>
                      <div className={styles.col}>
                        {fangJias.length > 1 ? (
                          <>
                            <span>
                              {dayjs(sd).format("M月D日")}
                            </span>
                            <span>｜</span>
                            <span>
                              {dayjs(lastSd).format("M月D日")}
                            </span>
                          </>
                        ) : (
                          <span>
                            {dayjs(sd).format("M月D日")}
                          </span>
                        )}
                      </div>
                      <div className={styles.col}>
                        {tiaoXius.length > 0 ? (
                          <>
                            {tiaoXius.map(({ startDate }, idx) => (
                              <span key={idx}>
                                {dayjs(startDate).format("M月D日")}
                              </span>
                            ))}
                          </>
                        ) : Chinese[language]('无调休')}
                      </div>
                      <div className={styles.col}>{Chinese[language](`${fangJias.length > 0 ? `共${fangJias.length}天` : '无假期'}`)}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
