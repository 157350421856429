// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";
import { Lunar } from "lunar-javascript";
import getGanZhi from "@/utils/tung-shing/get-ganzhi";
import classNames from "classnames";

// css
import styles from "./index.module.scss";

export default memo(() => {
  const { ymdStr, cnYmd, Chinese, language } = useContext(TungShingContext);
  const year = dayjs(ymdStr).year();
  const month = dayjs(ymdStr).month() + 1;

  const jiDays = []; // 吉日列表
  const xiongDays = []; // 凶日列表

  // 获取当前月份的总天数
  const totalDaysInMonth = dayjs().daysInMonth();

  for (let i = 1; i <= totalDaysInMonth; i++) {
    const ymdStr = `${year}-${month < 10 ? "0" + month : month}-${
      i < 10 ? "0" + i : i
    }`;
    const curDate = dayjs(ymdStr);
    const curLunar = Lunar.fromDate(curDate.toDate());

    const luck = curLunar.getDayTianShenLuck();

    const cnMd = `${month}月${i}号`;

    const ganZhi = getGanZhi(ymdStr).dayInGanZhi;

    const tianShen = curLunar.getDayTianShen();

    const tianShenType = curLunar.getDayTianShenType();

    if (luck === "吉") {
      jiDays.push({
        i,
        cnMd,
        ganZhi,
        luck,
        tianShen,
        tianShenType,
      });
    } else if (luck === "凶") {
      xiongDays.push({
        i,
        cnMd,
        ganZhi,
        luck,
        tianShen,
        tianShenType,
      });
    }
  }

  const allDays = jiDays.concat(xiongDays).sort((a, b) => {
    if (a.i < b.i) {
      return -1;
    }
    return 0;
  });

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd.slice(0, 7)}所有黄道吉日`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="content">
            <div className={styles.rows}>
              {jiDays.map(({ cnMd, ganZhi, luck, tianShen, tianShenType }, index) => {
                return (
                  <div className={styles.item} key={index}>
                    <div className={classNames({
                      ['day']: true,
                      good: luck === "吉",
                      bad: luck === "凶",
                    })}>
                      <span>
                        {cnMd}
                        {Chinese[language](`${ganZhi}日`)}
                      </span>
                    </div>
                    <div
                      className={classNames({
                        ["luck"]: true,
                        good: luck === "吉",
                        bad: luck === "凶",
                      })}
                    >
                      {Chinese[language](`${tianShen}/${tianShenType}日`)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
