"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";

export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);
  const cnYmd = dayjs(ymdStr).format("YYYY年M月D日");

  const lunarYmd = `${lunarData.lunarYmd.year}年${lunarData.lunarYmd.month}月${lunarData.lunarYmd.day}`;
  const ganzhi = `${lunarData.ganzhi.dayGan}${lunarData.ganzhi.dayZhi}`;
  const wuxing = lunarData.nayin.dayNaYin.slice(2);

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd}农历是多少`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="title">{Chinese[language]('今日农历是')}<span>{Chinese[language](lunarYmd)}</span></div>
          <div className="content">
            {Chinese[language](`${cnYmd}农历是${lunarYmd}；该日干支为${ganzhi}，因此叫`)}<span>{Chinese[language](`${ganzhi}日`)}</span>；{Chinese[language](`该日五行属${wuxing}`)}，{Chinese[language]('因此五行叫')}<span>{Chinese[language](`${wuxing}日`)}</span>。
          </div>
        </div>
      </div>
    </div>
  );
});
