"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";

export const zhiXingDesc = new Map([
  ['建', '指事情未成之初始。宜于建基立业，破土、开斧、开光、安座，此外一切均不宜'],
  ['除', '指清除的含意。除旧迎新大吉。'],
  ['满', '指圆满的含意。祭祀吉，其它不吉。最宜嫁娶。'],
  ['平', '指平顺之意。喜、凶事均可办。'],
  ['定', '指凡事皆有定。宜采纳、商贾、拜访、考试等，此外诸凶事不宜。'],
  ['执', '指凡事固执无变通。一切喜、凶事均宜忌。'],
  ['成', '指凡事成就。喜凶诸事均可办理。'],
  ['收', '指收回、收敛的含义。只宜收敛、索取、埋葬。此外诸凶事不宜。'],
  ['破', '指破裂，冲破的含义。忌办一切喜凶事，刑破日就是指此日而说。'],
  ['危', '凡事应小心之意。可办事但要小心，大事小用。'],
  ['开', '指开通顺利，百事可行。'],
  ['闭', '指不通闭塞。可以收敛、埋葬，余事不宜。'],
]);

export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);
  const cnYmd = dayjs(ymdStr).format("YYYY年M月D日");

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd}是什么建日`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="title">
            今日是<span>{Chinese[language](lunarData.zhixing)}日</span>
          </div>
          <div className="content">
            <p>{Chinese[language](`今天的黄道吉日是“${lunarData.zhixing}日”，${zhiXingDesc.get(lunarData.zhixing)}`)}</p>
            <p>{Chinese[language](`温馨提示：当日具体宜忌还需结合黄历来看哦。`)}</p>
          </div>
        </div>
      </div>
    </div>
  );
});
