"use client";

// hooks
import { memo, useContext } from "react";

// comp
// import Masonry from "react-masonry-css"; // <Masonry breakpointCols={3} className={styles.infoflow}>
// import Masonry from "react-responsive-masonry"; // <Masonry columnsCount={3} className={styles.infoflow}>
import Clothing from '@/components/clothing';
import Lucknum from '@/components/lucknum';
import Twelveday from '@/components/tung-shing/twelveday';
import Oneday from '@/components/oneday';
import GoodDay from "../goodday";
import CountDown from "../count-down";
import Nongli from "../nongli";
import ChongXiang from "../chong-xiang";
import Notification from "../notification";
import ZhiXing from "../zhi-xing";
import Timeline from "../timeline";
import Xiu from "../xiu";
import RiZhu from "../ri-zhu";
import YueXiang from "../yue-xiang";
import OneFifteen from "../one-fifteen";
import GooddayList from "../goodday-list";
import Holiday from "../holiday";
import Dayline from "../dayline";
import ZaoMaTou from "../zao-ma-tou";
// import WuHou from "../wu-hou";
// import ShuJiu from "../shu-jiu";
// import SanFu from "../san-fu";

// css
import styles from "./index.module.scss";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

export default memo(() => {
  const { cnYmd, Chinese, language } = useContext(TungShingContext);

  return (
    <div className={styles.infoflow}>
      <div className={styles.infocolumn}>
        {/*今天是否是吉日*/}
        <GoodDay />

        {/*五行穿衣颜色*/}
        <div className="infoitem">
          <h3>{Chinese[language](`${cnYmd}五行穿衣颜色`)}</h3>
          <div className="body">
            <div className="wrap">
              <div className="content">
                <Clothing />
              </div>
            </div>
          </div>
        </div>

        {/*是什么建星*/}
        <ZhiXing />

        {/*物侯*/}
        {/* <WuHou /> */}

        {/*二十八宿*/}
        <Xiu />

        {/*初一十五查询*/}
        <OneFifteen />

        {/*十二建日查询*/}
        <Twelveday />

        {/*数九寒天*/}
        {/* <ShuJiu /> */}

        {/*三伏天*/}
        {/* <SanFu /> */}

        {/*所有吉日*/}
        <GooddayList />

        {/*节日节气*/}
        <Dayline />

      </div>

      <div className={styles.infocolumn}>
        {/*小运播报*/}
        <Notification />

        {/*幸运数字*/}
        <div className="infoitem">
          <h3>{Chinese[language](`${cnYmd}幸运数字`)}</h3>
          <div className="body">
            <div className="wrap">
              <div className="content">
                <Lucknum />
              </div>
            </div>
          </div>
        </div>

        {/*今天冲什么生肖*/}
        <ChongXiang />

        {/*今天农历多少*/}
        <Nongli />

        {/*日柱日禄*/}
        <RiZhu />

        {/*历史今天*/}
        <div className="infoitem">
          <h3>{Chinese[language](`历史上的今天-${cnYmd.slice(5)}`)}</h3>
          <div className="body">
            <div className="wrap">
              <div className="content">
                <Oneday />
              </div>
            </div>
          </div>
        </div>

        {/*年放假安排*/}
        <Holiday />

      </div>

      <div className={styles.infocolumn}>
        {/*倒数日*/}
        <CountDown />

        {/*九星月相*/}
        <YueXiang />

        {/*时辰吉凶*/}
        <div className="infoitem">
          <h3>{Chinese[language](`${cnYmd}时辰吉凶`)}</h3>
          <div className="body">
            <div className="wrap">
              <Timeline />
            </div>
          </div>
        </div>

        {/*灶马头*/}
        <ZaoMaTou />

      </div>
    </div>
  );
});