"use client";

// hooks
import { memo, useContext } from "react";

// css
import styles from './index.module.scss';

// context
import { TungShingContext } from "@/components/tung-shing/provider";

export default memo(() => {
  const { pathname, Chinese, language } = useContext(TungShingContext);
  const nowYear = new Date().getFullYear();

  return <div className={styles.container}>
    <div className={styles.wrap}>
      <p>{Chinese[language](`${pathname === 'qmwnl' ? '全民万年历' : '万年历'}`)} Copyright &copy; {nowYear} All Right Reserved、<a href="https://beian.miit.gov.cn" target="_blank">icp备案: 粤ICP备2022138333号</a></p>
      <p>
        {Chinese[language](`本站所有内容仅供休闲娱乐，不作任何用途或决策之用，切忌迷信！`)}
        <a href="mailto:b18102290541@163.com">联系我们：b18102290541@163.com</a>
      </p>
    </div>
  </div>
});