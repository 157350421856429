"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";

// css
import styles from './index.module.scss';

export default memo(() => {
  const { ymdStr, lunarData } = useContext(TungShingContext);
  const cnYmd = dayjs(ymdStr).format("YYYY年M月D日");

  const {
    year: lunarYear,
    month: lunarMonth,
    day: lunarDay,
  } = lunarData.lunarYmd;

  const {
    yearInGanZhiByLiChun,
    monthInGanZhi,
    dayInGanZhi,
  } = lunarData.ganzhi;

  const {
    dayNaYin,
  } = lunarData.nayin;

  return (
    <div className="infoitem">
      <h3>{cnYmd}的日柱和日禄</h3>
      <div className="body">
        <div className="wrap">
          <div className="content">
            <div className="title">
              今日是<span>“{dayInGanZhi}”</span>
            </div>
            <div className={styles.rows}>
              <div className={styles.row}>
                <div className={styles.left}>公历</div>
                <div className={styles.right}>{cnYmd}</div>
              </div>

              <div className={styles.row}>
                <div className={styles.left}>农历</div>
                <div className={styles.right}>{lunarYear}年{lunarMonth}月{lunarDay}</div>
              </div>

              <div className={styles.row}>
                <div className={styles.left}>八字</div>
                <div className={styles.right}><span>{yearInGanZhiByLiChun}年 {monthInGanZhi}月 {dayInGanZhi}日</span></div>
              </div>

              <div className={styles.row}>
                <div className={styles.left}>五行</div>
                <div className={styles.right}>{dayNaYin.slice(2)}</div>
              </div>

              <div className={styles.row}>
                <div className={styles.left}>纳音</div>
                <div className={styles.right}>{dayNaYin}</div>
              </div>

              <div className={styles.row}>
                <div className={styles.left}>日禄</div>
                <div className={styles.right}>{lunarData.lu}</div>
              </div>

              <div className={styles.row}>
                <div className={styles.left}>神兽</div>
                <div className={styles.right}>{lunarData.shou}</div>
              </div>

              <div className={styles.row}>
                <div className={styles.left}>四宫</div>
                <div className={styles.right}>{lunarData.gong}宫</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
