"use client";

// hooks
import { memo, useContext, useEffect, useState } from "react";

// css
import styles from "./index.module.scss";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import classNames from "classnames";
import dayjs from "dayjs";
import IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import IsSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(IsSameOrAfter);
dayjs.extend(IsSameOrBefore);

// 黄历表格
export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);
  const cnYmd = dayjs(ymdStr).format("YYYY年M月D日");

  const [activeIndex, setActiveIndex] = useState(); // 被激活的高亮时间

  useEffect(() => {
    for (let i = 0; i < lunarData.timeList.length; i++) {
      const timeItem = lunarData.timeList[i];

      const { startTime, endTime } = timeItem;

      // 检查一个日期是否等于或者大于一个日期
      const isSameOrAfter = dayjs().isSameOrAfter(`${ymdStr} ${startTime}:00`);

      // 检查一个日期是否等于或者小于一个日期
      const isSameOrBefore = dayjs().isSameOrBefore(`${ymdStr} ${endTime}:00`);

      // 当前时间对应时辰高亮
      if (isSameOrAfter && isSameOrBefore) {
        setActiveIndex(i);
        break;
      }
    }
  }, [ymdStr]);

  // 时刻
  const times = [
    ["00:00", "00:59"],
    ["01:00", "02:59"],
    ["03:00", "04:59"],
    ["05:00", "06:59"],
    ["07:00", "08:59"],
    ["09:00", "10:59"],
    ["11:00", "12:59"],
    ["13:00", "14:59"],
    ["15:00", "16:59"],
    ["17:00", "18:59"],
    ["19:00", "20:59"],
    ["21:00", "22:59"],
    ["23:00", "23:59"],
  ];

  return (
    <div className={styles.tungshing}>
      <h2 className={styles.title}>
        {/* 择吉老黄历-黄道吉日择吉-时辰吉凶 */}
        {Chinese[language](`${cnYmd} ${lunarData.weekIndex} 时辰宜忌`)}
      </h2>
      <table border="0" cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td></td>
            <td>{Chinese[language]('子时')}</td>
            <td>{Chinese[language]('丑时')}</td>
            <td>{Chinese[language]('寅时')}</td>
            <td>{Chinese[language]('卯时')}</td>
            <td>{Chinese[language]('辰时')}</td>
            <td>{Chinese[language]('巳时')}</td>
            <td>{Chinese[language]('午时')}</td>
            <td>{Chinese[language]('未时')}</td>
            <td>{Chinese[language]('申时')}</td>
            <td>{Chinese[language]('酉时')}</td>
            <td>{Chinese[language]('戌时')}</td>
            <td>{Chinese[language]('亥时')}</td>
            <td>{Chinese[language]('子时')}</td>
          </tr>
          <tr>
            <td>{Chinese[language]('时刻')}</td>
            {times.map((time, index) => (
              <td
                key={index}
                className={classNames({
                  [styles.active]: index === activeIndex,
                })}
              >
                {time[0]}
                <br />
                {time[1]}
              </td>
            ))}
          </tr>
          <tr>
            <td>{Chinese[language]('时辰')}</td>
            {lunarData.timeList.map(({ ganzhi }, index) => (
              <td
                key={index}
                className={classNames({
                  [styles.active]: index === activeIndex,
                })}
              >
                {Chinese[language](ganzhi)}
              </td>
            ))}
          </tr>
          <tr>
            <td>{Chinese[language]('星神')}</td>
            {lunarData.timeList.map(({ tianshen }, index) => (
              <td
                key={index}
                className={classNames({
                  [styles.active]: index === activeIndex,
                })}
              >
                {Chinese[language](tianshen)}
              </td>
            ))}
          </tr>
          <tr className={styles.yi}>
            <td>{Chinese[language]('时宜')}</td>
            {lunarData.timeList.map(({ yi }, index) => {
              return (
                <td
                  key={index}
                  className={classNames({
                    [styles.active]: index === activeIndex,
                  })}
                >
                  <span datatype={yi.length <= 1 ? "one" : ""}>
                    {yi.map((str, idx) => (
                      <a key={idx}>{Chinese[language](str)}</a>
                    ))}
                  </span>
                </td>
              );
            })}
          </tr>
          <tr className={styles.ji}>
            <td>{Chinese[language]('时忌')}</td>
            {lunarData.timeList.map(({ ji }, index) => {
              return (
                <td
                  key={index}
                  className={classNames({
                    [styles.active]: index === activeIndex,
                  })}
                >
                  <span datatype={ji.length <= 1 ? "one" : ""}>
                    {ji.map((str, idx) => (
                      <a key={idx}>{Chinese[language](str)}</a>
                    ))}
                  </span>
                </td>
              );
            })}
          </tr>
          <tr className={styles.chong}>
            <td>{Chinese[language]('冲煞')}</td>
            {lunarData.timeList.map(
              ({ chongShengXiao, chongGan, chong }, index) => {
                return (
                  <td
                    key={index}
                    className={classNames({
                      [styles.active]: index === activeIndex,
                    })}
                  >
                    {Chinese[language](`冲${chongShengXiao}(${chongGan}${chong})`)}
                  </td>
                );
              }
            )}
          </tr>
          <tr className={styles.sha}>
            <td>{Chinese[language]('煞方')}</td>
            {lunarData.timeList.map(({ sha }, index) => {
              return (
                <td
                  key={index}
                  className={classNames({
                    [styles.active]: index === activeIndex,
                  })}
                >
                  {Chinese[language](`煞${sha}`)}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
});
