// hooks
import { memo, useState, useContext } from "react";

// css
import styles from "./index.module.scss";

// utils
import { lunarColors } from "./color";
import { Solar } from "lunar-javascript";
import dayjs from "dayjs";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// 图片地址
const img_url =
  "https://static.qmrl888.com/h5/calendar-hybrid/assets/images/clothing-guide/v3";

// 五行穿衣
export default memo((props) => {
  const { Chinese, language } = useContext(TungShingContext);

  const {
    dateObj = dayjs(),
  } = props;

  const [lunar] = useState(
    Solar.fromYmd(
      dateObj.year(),
      dateObj.month() + 1,
      dateObj.date()
    ).getLunar()
  );

  // 当天五行颜色
  const [color] = useState(lunarColors.get(lunar.getDayZhi()));

  return (
    <div className={styles.container}>
      <div className={styles.color}>
        <div className={styles.good}>
          <div className="left">
            <img src={`${img_url}/icon_good.png`} alt="大吉" />
          </div>
          <div className="right">
            {color.good.map((item, index) => {
              const text = item[0];
              const hex = item[1];
              return (
                <div className="dot" key={index}>
                  <i
                    style={{
                      background: hex,
                    }}
                  />
                  {Chinese[language](text)}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.nice}>
          <div className="left">
            <img src={`${img_url}/icon_nice.png`} alt="次吉" />
          </div>
          <div className="right">
            {color.nice.map((item, index) => {
              const text = item[0];
              const hex = item[1];
              return (
                <div className="dot" key={index}>
                  <i
                    style={{
                      background: hex,
                    }}
                  />
                  {Chinese[language](text)}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.fine}>
          <div className="left">
            <img src={`${img_url}/icon_fine.png`} alt="一般" />
          </div>
          <div className="right">
            {color.fine.map((item, index) => {
              const text = item[0];
              const hex = item[1];
              return (
                <div className="dot" key={index}>
                  <i
                    style={{
                      background: hex,
                    }}
                  />
                  {Chinese[language](text)}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.low}>
          <div className="left">
            <img src={`${img_url}/icon_low.png`} alt="较差" />
          </div>
          <div className="right">
            {color.low.map((item, index) => {
              const text = item[0];
              const hex = item[1];
              return (
                <div className="dot" key={index}>
                  <i
                    style={{
                      background: hex,
                    }}
                  />
                  {Chinese[language](text)}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.bad}>
          <div className="left">
            <img src={`${img_url}/icon_bad.png`} alt="不宜" />
          </div>
          <div className="right">
            {color.bad.map((item, index) => {
              const text = item[0];
              const hex = item[1];
              return (
                <div className="dot" key={index}>
                  <i
                    style={{
                      background: hex,
                    }}
                  />
                  {Chinese[language](text)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});
