"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";
import { shengXiaoChong } from '@/components/tung-shing/chong-xiang';

export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);
  const cnYmd = dayjs(ymdStr).format("YYYY年M月D日");

  const dayShengXiao = lunarData.shengxiao.dayShengXiao; // 今日生肖
  const sxchong = shengXiaoChong.get(dayShengXiao);
  const shuai = sxchong.chong.concat(sxchong.xing, sxchong.hai, sxchong.po).join('、'); // 带衰生肖

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd}小运播报`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="content">
            <p>
              <b>{Chinese[language](`特吉生肖：`)}</b><span>{Chinese[language](sxchong.liuhe.join('、'))}</span>
            </p>
            <p>
              <b>{Chinese[language](`次吉生肖：`)}</b><span>{Chinese[language](sxchong.sanhe.join('、'))}</span>
            </p>
            <p>
              <b>{Chinese[language](`生肖贵人：`)}</b><span>{Chinese[language](`属${dayShengXiao}的人`)}</span>
            </p>
            <p>
              <b>{Chinese[language](`带衰生肖：`)}</b><span>{Chinese[language](shuai)}</span>
            </p>
            <p>{Chinese[language](`今日属${shuai}的人状态可能欠佳，重要的事情建议择日再办哦。`)}</p>
            <p>{Chinese[language](`今天的煞方在${lunarData.chongsha.daySha}方，办事和出行建议避开${lunarData.chongsha.daySha}方哦。`)}</p>
            <p><b>{Chinese[language](`财神方位：`)}</b>在<span>{Chinese[language](lunarData.jishen.dayCai)}</span>{Chinese[language](`打麻将，打牌或求财的人可增强财运。`)}</p>
            <p><b>{Chinese[language](`喜神方位：`)}</b>在<span>{Chinese[language](lunarData.jishen.dayXi)}</span>{Chinese[language](`可增强桃花运和感情运。`)}</p>
            <p><b>{Chinese[language](`福神方位：`)}</b>在<span>{Chinese[language](lunarData.jishen.dayFu)}</span>{Chinese[language](`可增添福运。`)}</p>
            <p><b>{Chinese[language](`阳贵神方位：`)}</b>白天在<span>{Chinese[language](lunarData.jishen.dayYangGui)}</span>{Chinese[language](`容易找到帮助你的贵人。`)}</p>
            <p><b>{Chinese[language](`阴贵神方位：`)}</b>晚上在<span>{Chinese[language](lunarData.jishen.dayYinGui)}</span>{Chinese[language](`容易找到帮助你的贵人。`)}</p>
          </div>
        </div>
      </div>
    </div>
  );
});
