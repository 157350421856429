"use client";

// hooks
import { memo } from "react";

// css
import styles from './index.module.scss';

// utils
// import dynamic from 'next/dynamic';

// comp
import { FloatButton } from 'antd';
import { StarFilled } from '@ant-design/icons';
// const FloatButtonGroup = dynamic(
//   () =>
//     import("antd/es/float-button").then((mod) => {
//       return mod.Group;
//     }),
//   {
//     ssr: false,
//   }
// );
// const FloatButtonBackTop = dynamic(
//   () =>
//     import("antd/es/float-button").then((mod) => {
//       return mod.BackTop;
//     }),
//   {
//     ssr: false,
//   }
// );
// const FloatButton = dynamic(() => import('antd/es/float-button'), {
//   ssr: false,
// });

// 择吉日
export default memo(() => {
  return <FloatButton.Group shape="square">
    <FloatButton icon={<StarFilled style={{ color: 'var(--qm-color-warning)' }} />} tooltip={
      <div className={styles.qrcode}>
        <p>打开微信扫一扫</p>
        <p>
          <img src='https://static.qmrl888.com/h5/calendar-website/assets/images/qrcode/gzh_code.jpg' alt="全民万年历公众号" />
        </p>
        <p>关注公众号访问更方便</p>
      </div>
    } />
    <FloatButton.BackTop visibilityHeight={0} tooltip="回到顶部" />
  </FloatButton.Group>;
});