"use client";

// hooks
import { memo, useState } from "react";

// css
import styles from './index.module.scss';

// utils
import dayjs from "dayjs";
import { uniq } from '@/utils/remove-duplicate';
import isNumeric from '@/utils/is-numeric';

// 封装一个根据日期时间戳（秒）* 星期几 生成的数字
// 然后将数字拆分成数组，并且去重，去重后的数组超度不超过7位
// 不满7位的将重复的数字相加一起填满
export function generateLuckNum ({
  dateStr,
}) {
  const timestamp = dayjs(dateStr).format('YYYY-MM-DD');
  // const timestampInMilliseconds = dayjs(timestamp).valueOf(); // 单位为毫秒
  const timestampInSeconds = Math.floor(dayjs(timestamp).valueOf() / 100000); // 单位为秒
  // 获取今天是星期几
  const toweek = dayjs().day();

  //  dayjs 将星期天表示为 0，星期一表示为 1，以此类推，所以需要做一些调整
  // toweek <= 0 ? 7 : toweek
  const arrs = String(Math.ceil(timestampInSeconds * (toweek <= 0 ? 7 : toweek))).split('').reverse();
  const maxlen = 7;

  let {
    unique, // 去重后的选项
    duplicates, // 重复的选项
  } = uniq(arrs);

  duplicates = duplicates.map((item, idx) => {
    let value = item.value - 0; // 从string类型变成number类型
    let index = item.indexes[0]; // 重复项的原索引
    if (isNumeric(value)) { // 是数字的情况下 value + 原索引 + 现索引+1 = 变成新的值
      item.value = value + (index + (idx + 11));
    }
    return item;
  });

  const result = unique.concat(duplicates).map((item, idx) => {
    const { value, indexes } = item;
    if (isNumeric(value)) {
      return value - 0;
    } else { // 不是数字的时候提取indexes[0]索引做为数字
      return (unique[0].value - 0) + indexes[0] + (idx + 1);
    }
  });

  // 只保留maxlen长度
  return result.splice(0, maxlen);
}

export default memo((props) => {
  const {
    dateStr = dayjs().format('YYYY-MM-DD'),
  } = props;

  // 幸运数字
  const [lucknums] = useState(generateLuckNum({
    dateStr,
  }));

  return <div className={styles.container}>
    {lucknums.map((num, index) => <div className={styles.circle} key={index}>{num}</div>)}
  </div>
});