"use client";

// hooks
import { memo, useContext } from "react";

// comp
import Link from 'next/link';

// css
import styles from './index.module.scss';

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// 顶部导航条
export default memo(() => {
  const { Chinese, language } = useContext(TungShingContext);
  return <nav className={styles.container}>
    <div className={styles.wrap}>
      <Link href="/" className={styles.logo}>
        <img src="https://he.qmrl888.com/assets/png/pic_slogan-Bx7siVrU.png" alt="全民万年历" />
      </Link>
      <ul className={styles.menu}>
        {/* <li>
          <Link href="/">黄历</Link>
        </li>
        <li>
          <Link href="/">运势</Link>
        </li>
        <li>
          <Link href="/">解梦</Link>
        </li>
        <li>
          <Link href="/">测算</Link>
        </li> */}
        <li className="download">
          <a href="https://download.qmrl888.com/" target="_blank">
            <span>{Chinese[language]('APP下载')}</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>;
});