export const dayKeyValue = {
  'jiSi': '祭祀',
  'qiFu': '祈福',
  'qiuSi': '求嗣',
  'kaiGuang': '开光',
  'suHui': '塑绘',
  'qiJiao': '齐醮',
  'zhaiJiao': '斋醮',
  'muYu': '沐浴',
  'chouShen': '酬神',
  'zaoMiao': '造庙',
  'siZhao': '祀灶',
  'fenXiang': '焚香',
  'xieTu': '谢土',
  'chuHuo': '出火',
  'diaoKe': '雕刻',
  'jiaQu': '嫁娶',
  'DingHun': '订婚',
  'naCai': '纳采',
  'wenMing': '问名',
  'naXu': '纳婿',
  'guiNing': '归宁',
  'anChuang': '安床',
  'heZhang': '合帐',
  'guanJi': '冠笄',
  'dingMeng': '订盟',
  'jinRenKou': '进人口',
  'caiYi': '裁衣',
  'wanMian': '挽面',
  'kaiRong': '开容',
  'xiuFen': '修坟',
  'qiZuan': '启钻',
  'poTu': '破土',
  'anZang': '安葬',
  'liBei': '立碑',
  'chengFu': '成服',
  'chuFu': '除服',
  'kaiShengFen': '开生坟',
  'heShouMu': '合寿木',
  'ruLian': '入殓',
  'yiJiu': '移柩',
  'puDu': '普渡',
  'ruZhai': '入宅',
  'anXiang': '安香',
  'anMen': '安门',
  'xiuZao': '修造',
  'qiJi': '起基',
  'dongTu': '动土',
  'shangLiang': '上梁',
  'shuZhu': '竖柱',
  'kaiJing': '开井开池',
  'zuoBei': '作陂放水',
  'chaiXie': '拆卸',
  'poWu': '破屋',
  'huaiYuan': '坏垣',
  'buYuan': '补垣',
  'faMuZuoLiang': '伐木做梁',
  'zuoZhao': '作灶',
  'jieChu': '解除',
  'kaiZhuYan': '开柱眼',
  'chuanPing': '穿屏扇架',
  'gaiWuHeJi': '盖屋合脊',
  'kaiCe': '开厕',
  'zaoCang': '造仓',
  'saiXue': '塞穴',
  'pingZhi': '平治道涂',
  'zaoQiao': '造桥',
  'zuoCe': '作厕',
  'zhuDi': '筑堤',
  'kaiChi': '开池',
  'faMu': '伐木',
  'kaiQu': '开渠',
  'jueJing': '掘井',
  'saoShe': '扫舍',
  'fangShui': '放水',
  'zaoWu': '造屋',
  'heJi': '合脊',
  'zaoChuChou': '造畜稠',
  'xiuMen': '修门',
  'dingSang': '定磉',
  'zuoLiang': '作梁',
  'xiuShi': '修饰垣墙',
  'jiaMa': '架马',
  'kaiShi': '开市',
  'guaBian': '挂匾',
  'naChai': '纳财',
  'qiuCai': '求财',
  'kaiCang': '开仓',
  'maiChe': '买车',
  'zhiChan': '置产',
  'guYong': '雇庸',
  'chuHuoCai': '出货财',
  'anJiXie': '安机械',
  'zaoCheQi': '造车器',
  'jingLuo': '经络',
  'yunNiang': '酝酿',
  'zuoRan': '作染',
  'guZhu': '鼓铸',
  'zaoChuan': '造船',
  'geMi': '割蜜',
  'zaiZhong': '栽种',
  'quYu': '取渔',
  'jieWang': '结网',
  'muYang': '牧养',
  'anDuiWei': '安碓磑',
  'xiYi': '习艺',
  'ruXue': '入学',
  'liFa': '理发',
  'tanBing': '探病',
  'jianGui': '见贵',
  'chengChuan': '乘船',
  'duShui': '渡水',
  'zhenJiu': '针灸',
  'chuXing': '出行',
  'yiXi': '移徙',
  'fenJu': '分居',
  'TiTou': '剃头',
  'zhengShou': '整手足甲',
  'naChu': '纳畜',
  'buZhuo': '捕捉',
  'tianLie': '畋猎',
  'jiaoNiuMa': '教牛马',
  'huiQinYou': '会亲友',
  'fuRen': '赴任',
  'qiuYi': '求医',
  'zhiBing': '治病',
  'ciSong': '词讼',
  'qiJiDongTu': '起基动土',
  'poWuHuaiYuan': '破屋坏垣',
  'gaiWu': '盖屋',
  'zaoCangKu': '造仓库',
  'liQuanJiaoYi': '立券交易',
  'jiaoYi': '交易',
  'liQuan': '立券',
  'anJi': '安机',
  'huiYou': '会友',
  'qiuYiLiaoBing': '求医疗病',
  'zhuShi': '诸事不宜',
  'yuShi': '馀事勿取',
  'xingSang': '行丧',
  'duanYi': '断蚁',
  'guiXiu': '归岫',
};

export const dayMap = new Map(Object.entries(dayKeyValue));

export const dayNames = Object.entries(dayKeyValue).map(([value, label]) => ({ label, value }));