"use client";

// hooks
import { memo, useContext, useEffect, useState } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// css
import styles from "./index.module.scss";

// comp
import { Timeline } from "antd";

// utils
import classNames from "classnames";
import dayjs from "dayjs";
import IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import IsSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(IsSameOrAfter);
dayjs.extend(IsSameOrBefore);

export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);

  const [items, setItems] = useState(
    (() => {
      const results = [];
      for (const timeItem of lunarData.timeList) {
        const {
          startTime,
          endTime,
          tianshen,
          ganzhi,
          zhi,
          yi,
          ji,
          tianshenLuck,
          sha,
          chongShengXiao,
        } = timeItem;
        results.push({
          dot: <div className={styles.dot}>{Chinese[language](zhi)}</div>,
          children: (
            <div className={styles.card}>
              <div className={styles.row}>
                {Chinese[language](`${ganzhi}时`)}
                <span>
                  {startTime}-{endTime}
                </span>
              </div>
              <div className={styles.row}>
                {Chinese[language](`冲煞：冲${chongShengXiao} 煞${sha}丨星神：${tianshen}`)}
              </div>
              <div className={styles.yi}>{Chinese[language](`时宜：${yi.join("\n")}`)}</div>
              <div className={styles.ji}>{Chinese[language](`时忌：${ji.join("\n")}`)}</div>
              <div className={styles[tianshenLuck === "吉" ? "good" : "bad"]}>
                {Chinese[language](tianshenLuck)}
              </div>
            </div>
          ),
        });
      }
      return results;
    })()
  );

  useEffect(() => {

    for (let i = 0; i < lunarData.timeList.length; i++) {
      const timeItem = lunarData.timeList[i];

      const { startTime, endTime, zhi } = timeItem;

      // 检查一个日期是否等于或者大于一个日期
      const isSameOrAfter = dayjs().isSameOrAfter(`${ymdStr} ${startTime}:00`);

      // 检查一个日期是否等于或者小于一个日期
      const isSameOrBefore = dayjs().isSameOrBefore(`${ymdStr} ${endTime}:00`);

      // 当前时间对应时辰高亮
      if (isSameOrAfter && isSameOrBefore) {
        items[i].dot = (
          <div
            className={classNames({
              [styles.dot]: true,
              [styles.active]: true,
            })}
          >
            {zhi}
          </div>
        );
        break;
      }
    }

    // 当前时间
    setItems([
      ...items,
    ]);
  }, [ymdStr]);

  // 获取吉时
  const jiTimes = lunarData.timeList.filter(
    ({ tianshenLuck }) => tianshenLuck === "吉"
  );
  const xiongTimes = lunarData.timeList.filter(
    ({ tianshenLuck }) => tianshenLuck === "凶"
  );

  return (
    <>
      <div className="title">
        {Chinese[language]('今日有')}
        <span style={{ color: "var(--qm-color-success)" }}>
          {jiTimes.length}{Chinese[language]('个吉时')}
        </span>
        、
        <span style={{ color: "var(--qm-color-danger)" }}>
          {xiongTimes.length}{Chinese[language]('个凶时')}
        </span>
      </div>
      <div className={styles.container}>
        <div className="content">
          <Timeline items={items} />
        </div>
      </div>
    </>
    // <div className="infoitem">
    //   <div className="head">{cnYmd}时辰吉凶</div>
    //   <div className="body">
    //     <div className="wrap">
    //       <div className="title">今日有 <span style={{ color: 'var(--qm-color-success)' }}>6个吉时</span>、<span>6个凶时</span></div>
    //       <div className="content">
    //         <p>丙子时 23:00-00:59<span style={{ color: 'var(--qm-color-success)' }}>（吉）</span></p>
    //         <p>丁丑时 01:00-02:59<span style={{ color: 'var(--qm-color-success)' }}>（吉）</span></p>
    //         <p>戊寅时 03:00-04:59<span>（凶）</span></p>
    //         <p>己卯时 05:00-06:59<span style={{ color: 'var(--qm-color-success)' }}>（吉）</span></p>
    //         <p>庚辰时 07:00-08:59<span>（凶）</span></p>
    //         <p>辛巳时 09:00-10:59<span>（凶）</span></p>
    //         <p>壬午时 11:00-12:59<span style={{ color: 'var(--qm-color-success)' }}>（吉）</span></p>
    //         <p>癸未时 13:00-14:59<span>（凶）</span></p>
    //         <p>甲申时 15:00-16:59<span style={{ color: 'var(--qm-color-success)' }}>（吉）</span></p>
    //         <p>乙酉时 17:00-18:59<span style={{ color: 'var(--qm-color-success)' }}>（吉）</span></p>
    //         <p>丙戌时 19:00-20:59<span>（凶）</span></p>
    //         <p>丁亥时 21:00-22:59<span>（凶）</span></p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
});
