"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";
import { Lunar } from "lunar-javascript";

// 建除十二星
export default memo(() => {
  const { ymdStr, cnYmd, Chinese, language } = useContext(TungShingContext);
  const year = dayjs(ymdStr).year();
  const month = dayjs(ymdStr).month() + 1;

  // 获取当前月份的总天数
  const totalDaysInMonth = dayjs().daysInMonth();

  // 初一的日期
  let chuYi = null;

  // 十五的日期
  let shiWu = null;

  for (let i = 1; i <= totalDaysInMonth; i++) {
    const ymdStr = `${year}-${month < 10 ? "0" + month : month}-${
      i < 10 ? "0" + i : i
    }`;
    const curDate = dayjs(ymdStr);
    const curLunar = Lunar.fromDate(curDate.toDate());

    const cnDay = curLunar.getDayInChinese();

    if (cnDay === '初一') {
      chuYi = `${month}月${i}日`;
    }

    if (cnDay === '十五') {
      shiWu = `${month}月${i}日`;
      break;
    }
  }

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd.slice(0, 7)}初一十五查询`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="content">
            <p>
              <b>{chuYi}是</b>
              <span>初一</span>
            </p>
            <p>
              <b>{shiWu}是</b>
              <span>十五</span>
            </p>
            <p>
              {Chinese[language](`初一十五是一个月中的具体两天，初一是农历每月的第一天，十五是农历每月的第十五天，同时，初一十五也是佛教中的十斋日，《地藏经》言：“能于是十斋日，对佛菩萨诸贤圣像前，读是经一遍，东西南北百由旬内，无诸灾难。”`)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
