// 静态图片地址
const img_url = 'https://static.qmrl888.com/h5/calendar-hybrid/assets/images/clothing-guide/v3/introduction/clothing';

// 每日五行穿衣数据
const colors = new Map([
  [
    '白色',
    ['白色', '#FFFFFF', {
      female: `${img_url}/female/white.jpg`,
      male: `${img_url}/male/white.png`,
    }]
  ],
  [
    '银白色',
    ['银白色', '#F8F5F1', {
      female: `${img_url}/female/silvery-white.jpg`,
      male: `${img_url}/male/silvery-white.png`,
    }]
  ],
  [
    '咖啡色',
    ['咖啡色', '#7D5A50', {
      female: `${img_url}/female/coffee.png`,
      male: `${img_url}/male/coffee.png`,
    }]
  ],
  [
    '黄色',
    ['黄色', '#FACE7F', {
      female: `${img_url}/female/yellow.jpg`,
      male: `${img_url}/male/yellow.png`,
    }]
  ],
  [
    '焦糖色',
    ['焦糖色', '#86340A', {
      female: `${img_url}/female/caramel.png`,
      male: `${img_url}/male/caramel.png`,
    }]
  ],
  [
    '绿色',
    ['绿色', '#57837B', {
      female: `${img_url}/female/green.jpg`,
      male: `${img_url}/male/green.png`,
    }]
  ],
  [
    '蓝色',
    ['蓝色', '#8FC1D4', {
      female: `${img_url}/female/blue.jpg`,
      male: `${img_url}/male/blue.png`,
    }]
  ],
  [
    '红色',
    ['红色', '#A9333A', {
      female: `${img_url}/female/red.png`,
      male: `${img_url}/male/red.png`,
    }]
  ],
  [
    '粉色',
    ['粉色', '#E2BCB7', {
      female: `${img_url}/female/pink.png`,
      male: `${img_url}/male/pink.png`,
    }]
  ],
  [
    '黑色',
    ['黑色', '#080202', {
      female: `${img_url}/female/black.jpg`,
      male: `${img_url}/male/black.png`,
    }]
  ],
  [
    '深蓝色',
    ['深蓝色', '#4B778D', {
      female: `${img_url}/female/dark-blue.png`,
      male: `${img_url}/male/dark-blue.png`,
    }]
  ],
  [
    '橙色',
    ['橙色', '#E48257', {
      female: `${img_url}/female/orange.png`,
      male: `${img_url}/male/orange.png`,
    }]
  ],
  [
    '紫色',
    ['紫色', '#907FA4', {
      female: `${img_url}/female/purple.png`,
      male: `${img_url}/male/purple.png`,
    }]
  ],
  [
    '浅蓝色',
    ['浅蓝色', '#DAEAF1', {
      female: `${img_url}/female/baby-blue.png`,
      male: `${img_url}/male/baby-blue.png`,
    }]
  ],
  [
    '青色',
    ['青色', '#94D0CC', {
      female: `${img_url}/female/cyan.png`,
      male: `${img_url}/male/cyan.png`,
    }]
  ],
  [
    '银色',
    ['银色', '#EAEAEA', {
      female: `${img_url}/female/silvery.png`,
      male: `${img_url}/male/silvery.png`,
    }]
  ],
  [
    '泥土色',
    ['泥土色', '#865439', {
      female: `${img_url}/female/earth-tone.png`,
      male: `${img_url}/male/earth-tone.png`,
    }]
  ],
  [
    '粉红色',
    ['粉红色', '#F9DFDC', {
      female: `${img_url}/female/pink-red.png`,
      male: `${img_url}/male/pink-red.png`,
    }]
  ],
  [
    '咖啡红',
    ['咖啡红', '#835151', {
      female: `${img_url}/female/coffee-red.png`,
      male: `${img_url}/male/coffee-red.png`,
    }]
  ],
  [
    '深灰色',
    ['深灰色', '#9D9D9D', {
      female: `${img_url}/female/dark-grey.png`,
      male: `${img_url}/male/dark-grey.png`,
    }]
  ],
  [
    '玫红色',
    ['玫红色', '#C37B89', {
      female: `${img_url}/female/rose.png`,
      male: `${img_url}/male/rose.png`,
    }]
  ],
  [
    '金色',
    ['金色', '#F4D19B', {
      female: `${img_url}/female/golden.jpg`,
      male: `${img_url}/male/golden.png`,
    }]
  ],
  [
    '浅灰色',
    ['浅灰色', '#DBDBDB', {
      female: `${img_url}/female/light-gray.png`,
      male: `${img_url}/male/light-gray.png`,
    }]
  ],
]);

export const lunarColors = new Map([
  [
    '未',
    {
      // 大吉
      good: [
        colors.get('白色'),
        colors.get('银白色'),
      ],
      // 次吉
      nice: [
        colors.get('咖啡色'),
        colors.get('黄色'),
        colors.get('焦糖色'),
      ],
      // 一般
      fine: [
        colors.get('绿色'),
        colors.get('蓝色'),
      ],
      // 较差
      low: [
        colors.get('红色'),
        colors.get('粉色'),
      ],
      // 不宜
      bad: [
        colors.get('黑色'),
        colors.get('深蓝色'),
      ],
    }
  ],

  [
    '午',
    {
      good: [
        colors.get('黄色'),
        colors.get('咖啡色'),
      ],
      // 次吉
      nice: [
        colors.get('红色'),
        colors.get('粉色'),
        colors.get('橙色'),
        colors.get('紫色'),
      ],
      // 一般
      fine: [
        colors.get('黑色'),
        colors.get('浅蓝色'),
      ],
      // 较差
      low: [
        colors.get('绿色'),
        colors.get('青色'),
        colors.get('深蓝色'),
      ],
      bad: [
        colors.get('白色'),
        colors.get('银色'),
      ]
    }
  ],

  [
    '巳',
    {
      good: [
        colors.get('黄色'),
        colors.get('咖啡色'),
      ],
      // 次吉
      nice: [
        colors.get('红色'),
        colors.get('粉色'),
        colors.get('橙色'),
        colors.get('紫色'),
      ],
      // 一般
      fine: [
        colors.get('黑色'),
        colors.get('浅蓝色'),
      ],
      // 较差
      low: [
        colors.get('绿色'),
        colors.get('青色'),
        colors.get('深蓝色'),
      ],
      bad: [
        colors.get('白色'),
        colors.get('银色'),
      ]
    }
  ],

  [
    '辰',
    {
      good: [
        colors.get('白色'),
        colors.get('银色'),
      ],
      // 次吉
      nice: [
        colors.get('黄色'),
        colors.get('咖啡色'),
        colors.get('泥土色'),
      ],
      // 一般
      fine: [
        colors.get('绿色'),
      ],
      // 较差
      low: [
        colors.get('红色'),
        colors.get('粉红色'),
      ],
      bad: [
        colors.get('黑色'),
        colors.get('深蓝色'),
      ]
    }
  ],

  [
    '卯',
    {
      good: [
        colors.get('红色'),
        colors.get('粉色'),
        colors.get('橙色'),
        colors.get('紫色'),
      ],
      // 次吉
      nice: [
        colors.get('绿色'),
        colors.get('青色'),
        colors.get('深蓝色'),
      ],
      // 一般
      fine: [
        colors.get('白色'),
        colors.get('银色'),
      ],
      // 较差
      low: [
        colors.get('黑色'),
        colors.get('浅蓝色'),
      ],
      bad: [
        colors.get('黄色'),
        colors.get('咖啡色'),
      ]
    }
  ],

  [
    '寅',
    {
      good: [
        colors.get('红色'),
        colors.get('粉色'),
        colors.get('橙色'),
        colors.get('紫色'),
      ],
      // 次吉
      nice: [
        colors.get('绿色'),
        colors.get('青色'),
        colors.get('深蓝色'),
      ],
      // 一般
      fine: [
        colors.get('白色'),
        colors.get('银色'),
      ],
      // 较差
      low: [
        colors.get('黑色'),
        colors.get('浅蓝色'),
      ],
      bad: [
        colors.get('黄色'),
        colors.get('咖啡色'),
      ]
    }
  ],

  [
    '丑',
    {
      good: [
        colors.get('白色'),
        colors.get('银色'),
      ],
      // 次吉
      nice: [
        colors.get('黄色'),
        colors.get('咖啡色'),
      ],
      // 一般
      fine: [
        colors.get('绿色'),
        colors.get('青色'),
        colors.get('深蓝色'),
      ],
      // 较差
      low: [
        colors.get('红色'),
        colors.get('粉色'),
        colors.get('橙色'),
        colors.get('紫色'),
      ],
      bad: [
        colors.get('黑色'),
        colors.get('浅蓝色'),
      ]
    }
  ],

  [
    '子',
    {
      good: [
        colors.get('绿色'),
        colors.get('青色'),
      ],
      // 次吉
      nice: [
        colors.get('黑色'),
        colors.get('浅蓝色'),
      ],
      // 一般
      fine: [
        colors.get('黄色'),
        colors.get('咖啡色'),
      ],
      // 较差
      low: [
        colors.get('白色'),
        colors.get('银色'),
      ],
      bad: [
        colors.get('红色'),
        colors.get('粉色'),
        colors.get('橙色'),
        colors.get('紫色'),
      ]
    }
  ],

  [
    '亥',
    {
      good: [
        colors.get('绿色'),
        colors.get('蓝色'),
      ],
      // 次吉
      nice: [
        colors.get('黑色'),
        colors.get('深蓝色'),
      ],
      // 一般
      fine: [
        colors.get('黄色'),
        colors.get('焦糖色'),
        colors.get('咖啡红'),
        colors.get('深灰色'),
      ],
      // 较差
      low: [
        colors.get('白色'),
        colors.get('银色'),
        colors.get('浅灰色'),
      ],
      bad: [
        colors.get('红色'),
        colors.get('紫色'),
        colors.get('玫红色'),
        colors.get('粉色'),
      ]
    }
  ],

  [
    '戌',
    {
      good: [
        colors.get('白色'),
        colors.get('银色'),
      ],
      // 次吉
      nice: [
        colors.get('黄色'),
        colors.get('咖啡色'),
        colors.get('泥土色'),
      ],
      // 一般
      fine: [
        colors.get('绿色'),
      ],
      // 较差
      low: [
        colors.get('红色'),
        colors.get('粉红色'),
      ],
      bad: [
        colors.get('黑色'),
        colors.get('深蓝色'),
      ]
    }
  ],

  [
    '酉',
    {
      good: [
        colors.get('黑色'),
        colors.get('深蓝色'),
      ],
      // 次吉
      nice: [
        colors.get('白色'),
        colors.get('银白色'),
        colors.get('浅灰色'),
      ],
      // 一般
      fine: [
        colors.get('红色'),
        colors.get('紫色'),
        colors.get('玫红色'),
        colors.get('粉色'),
      ],
      // 较差
      low: [
        colors.get('黄色'),
        colors.get('焦糖色'),
        colors.get('咖啡色'),
      ],
      bad: [
        colors.get('绿色'),
        colors.get('蓝色'),
      ]
    }
  ],

  [
    '申',
    {
      good: [
        colors.get('黑色'),
        colors.get('深蓝色'),
        colors.get('深灰色'),
      ],
      // 次吉
      nice: [
        colors.get('白色'),
        colors.get('银色'),
        colors.get('金色'),
        colors.get('浅灰色'),
      ],
      // 一般
      fine: [
        colors.get('红色'),
        colors.get('粉色'),
      ],
      // 较差
      low: [
        colors.get('黄色'),
        colors.get('咖啡色'),
        colors.get('泥土色'),
        colors.get('焦糖色'),
      ],
      bad: [
        colors.get('绿色'),
        colors.get('青色'),
      ]
    }
  ],
]);