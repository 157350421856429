// utils
import dayjs from 'dayjs';
import { Solar } from 'lunar-javascript';

// 获取阳历节日
export default function getSolarFestival (ymdStr = dayjs().format('YYYY-MM-DD')) {
  const curDate = dayjs(ymdStr);
  const curSolar = Solar.fromYmd(curDate.year(), curDate.month() + 1, curDate.date());

  // 返回节日的数组，包括元旦节、国庆节等，也包括母亲节、父亲节、感恩节、圣诞节等，有可能同一天有多个，也可能没有
  const festivals = curSolar.getFestivals() || [];

  // 返回其他纪念日的数组，例如世界抗癌日、香港回归纪念日等，有可能同一天有多个，也可能没有
  const otherFestivals = curSolar.getOtherFestivals() || [];

  // console.log(festivals, otherFestivals);

  return festivals.concat(otherFestivals);
}