"use client";

// hooks
import { createContext } from "react";

// utils
import { Chinese } from '@/utils/chinese-s2t';

// 上下文数据共享
export const TungShingContext = createContext({});

export default function TungShingProvider({ value, children }) {
  return (
    <TungShingContext.Provider
      value={{
        ...value,
        Chinese,
      }}
    >
      {children}
    </TungShingContext.Provider>
  );
}
