"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";

export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);
  const cnYmd = dayjs(ymdStr).format("YYYY年M月D日");

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd}二十八宿`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="title">
            {Chinese[language](`今日是东方七宿`)} <span>{Chinese[language](lunarData.xiu.xiu)}</span>，{Chinese[language]('对应的七政是')} <span>{Chinese[language](lunarData.xiu.zheng)}</span>
            ，{Chinese[language](`对应的动物是`)} <span>{Chinese[language](lunarData.xiu.animal)}</span>
          </div>
          <div className="content">
            <p>{Chinese[language](`东方七宿：角、亢、氐、房、心、尾、箕；`)}</p>
            <p>{Chinese[language](`北方七宿：斗、牛、女、虚、危、室、壁；`)}</p>
            <p>{Chinese[language](`西方七宿：奎、娄、胃、昴、毕、觜、参；`)}</p>
            <p>{Chinese[language](`南方七宿：井、鬼、柳、星、张、翼、轸。`)}</p>
            <p>
              {Chinese[language](`星宿歌诀：`)}
              <span>
                {Chinese[language](lunarData.xiu.xiuSong)}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
